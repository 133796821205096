import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import YashogathLayout from '../../layouts/yashogatha/yashogatha.layout'

const YashogathaPage = () => {
  return (
    <>
        <PageHeaderComponent title="Yashogatha"/>
        <div>
            <YashogathLayout/>
        </div>
    </>
  )
}

export default YashogathaPage