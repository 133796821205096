import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import { Outlet } from 'react-router-dom'

export const AlumniGalleryContainerLayout = () => {
    return (
        (<>
            <div>
                <Outlet />
            </div>
        </>)
    )
}
