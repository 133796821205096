import React, { useState, useEffect, useRef } from 'react';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import './media.layout.scss';

const MediaTalkLayout = () => {
    let emptyMedia = {
        'id': '',
        'description': '',
        'title': '',
        'isLink': false,
        'url': '',
        'source': '',
        'publish_date': null,
        'institute_id': 0,
        'tid': 1,
        'created_by': 1,
        'is_active': null,
        'file': null
    }

    const [publishDate, setPublishDate] = useState(new Date());
    const [medias, setMedias] = useState([]);
    const [mediaDialog, setMediaDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [media, setMedia] = useState(emptyMedia);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET_ALL_MEDIA_TALKS', 'MEDIA_TALKS'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setMedias(resp.data.data);
            }
        }, (err) => {
            //console.log(err)
        })
    }

    if (!medias) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setMedia(emptyMedia);
        setSubmitted(false);
        setMediaDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setMediaDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (media.title.trim()) {
            let _medias = [...medias];
            let _media = { ...media };

            if (media.id) {
                const data = new FormData();
                data.append('id', _media.id)
                data.append('description', _media.description);
                data.append('title', _media.title);
                data.append('isLink', _media.isLink);
                data.append('url', _media.url);
                data.append('source', _media.source);
                data.append('publish_date', _media.publish_date);
                data.append('institute_id', '0');
                data.append('tid', '1');
                data.append('created_by', '1');
                data.append('is_active', '1');
                data.append('file', selectedFile);

                let params = [];
                params.push(media.id);
                putData(getURL('CREATE_MEDIA_TALKS', 'MEDIA_TALKS', params), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Media Talk Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Media Talk Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();
                data.append('desc', _media.desc);
                data.append('description', _media.description);
                data.append('title', _media.title);
                data.append('isLink', _media.isLink);
                data.append('url', _media.url);
                data.append('source', _media.source);
                data.append('publish_date', _media.publish_date);
                data.append('institute_id', '1');
                data.append('tid', '1');
                data.append('created_by', '1');
                data.append('is_active', '1');
                data.append('file', selectedFile);


                postData(getURL('CREATE_MEDIA_TALKS', 'MEDIA_TALKS'), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Media Talk Created', life: 3000 });

                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Media Talk Not Created', life: 3000 });

                })

            }

            setMedias(_medias);
            setMediaDialog(false);
            setMedia(emptyMedia);
        }
    };

    const edit = (media) => {

        setMedia({ ...media });
        setMediaDialog(true);

    };

    const confirmDelete = (media) => {
        setMedia(media);
        setDeleteDialog(true);
    };


    const deleteMedia = () => {
        let _medias = medias.filter((val) => val.id !== media.id);
        let params = [];
        params.push(media.id);
        deleteRecord(getURL('CREATE_MEDIA_TALKS', 'MEDIA_TALKS', params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setMedias(_medias);
        setDeleteDialog(false);
        setMedia(emptyMedia);

    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < medias.length; i++) {
            if (medias[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _medias = medias.filter((val) => !selectedRecords.includes(val));
        setMedias(_medias);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _media = { ...media };

        _media[`${name}`] = val;
        setMedia(_media);
    };

    const onDateSelected = (date) => {
        let _media = { ...media };

        _media['publish_date'] = date;
        setMedia(_media);
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>

            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        if (rowData.file_url)
            return <img src={rowData.file_url} alt={rowData.title} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Awards</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteMedia} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };

    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={medias} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} awards" globalFilter={globalFilter} header={header}>

                    <Column field="id" header="ID" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="title" header="Title" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="description" header="Description" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="url" header="Link" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="source" header="Source" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="publish_date" header="Publish Date" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="file_url" header="Image" body={imageBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={mediaDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Media Talk Details" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {media.avtar_url && <img src={media.file} alt={media.title} className="award-image block m-auto pb-3" />}
                <div className="field">
                    <label htmlFor="heading" className="font-bold">
                        Title
                    </label>
                    <InputText id="heading" value={media.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !media.title })} />
                    {submitted && !media.title && <small className="p-error">Heading is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="desc" className="font-bold">
                        Short Description
                    </label>
                    <InputText id="desc" value={media.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus className={classNames({ 'p-invalid': submitted && !media.description })} />
                    {submitted && !media.description && <small className="p-error">Description is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="source" className="font-bold">
                        Source
                    </label>
                    <InputText id="source" value={media.source} onChange={(e) => onInputChange(e, 'source')} required autoFocus className={classNames({ 'p-invalid': submitted && !media.source })} />
                    {submitted && !media.source && <small className="p-error">Source is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="url" className="font-bold">
                        Link
                    </label>
                    <InputText id="url" value={media.url} onChange={(e) => onInputChange(e, 'url')} required autoFocus className={classNames({ 'p-invalid': submitted && !media.url })} />
                    {submitted && !media.url && <small className="p-error">Link is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="date" className="font-bold">
                        Publish Date
                    </label>
                    <DatePicker id="date" selected={media.publish_date} onChange={(date) => onDateSelected(date)} dateFormat="yyyy-MM-dd 00:00:00" required autoFocus className={classNames({ 'p-invalid': submitted && !media.publish_date })} />
                    {submitted && !media.publish_date && <small className="p-error">Date is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>


            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {media && (
                        <span>
                            Are you sure you want to delete <b>{media.title}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {medias && <span>Are you sure you want to delete the selected Media talks?</span>}
                </div>
            </Dialog>
        </div>
    );


}

export default MediaTalkLayout