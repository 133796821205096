import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import AdmissionStudentLinksLayout from '../../layouts/admission-student-link/admission-student-link.layout'


const AdmissionStudentLinkPage = ({title, category}) => {
  return (
    <>
        <PageHeaderComponent title={title} />
        <AdmissionStudentLinksLayout title={title} category={category} />
    </>
  )
}

export default AdmissionStudentLinkPage