import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { isAuthenticated } from '../common/auth.common'

export const PrivateRoute = () => {
    let isLoggedIn = isAuthenticated();
    return (
        isLoggedIn == "true" ? <Outlet /> : <Navigate to="/login" />
    )
}
