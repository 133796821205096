import React, { useState, useEffect, useRef } from 'react';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';

const NoticeLayout = ({ api, title, institute_id }) => {
    let emptyModel = {
        title: '',
        category: '',
        description: '',
        file: null,
        year_code: '',
        tid: 1,
        institute_id: 1,
        created_by: 1,
        is_active: null
    }

    const [years, setYears] = useState([]);
    const [year, setYear] = useState(null)
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null)
    const [datas, setDatas] = useState([]);
    const [dataDialog, setDataDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [data, setData] = useState(emptyModel);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchYears();
        fetchCategory();
        fetchAll();
    }, []);

    const fetchYears = () => {
        getData(getURL('GET_ACADEMIC_YEAR', 'ACADEMIC_YEARS'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setYears(resp.data.data);
            }
        }, (err) => {

        })
    }

    const fetchCategory = () => {
        getData(getURL('GET_ALL_NOTICE_TYPES', 'NOTICE_TYPE'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setCategories(resp.data.data);
            }
        }, (err) => {

        })
    }

    const fetchAll = () => {
        let params = [];
        //params.push("institute");
        //params.push(institute_id);
        getData(getURL('GET', api, params), (resp) => {
            setDatas([])
            if (resp.status === 200 && resp.statusText === 'OK') {
                setDatas(resp.data.data);
            }
        }, (err) => {
            //console.log(err)
            setDatas([])
        })
    }

    if (!datas) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setData(emptyModel);
        setSubmitted(false);
        setDataDialog(true);
        setYear(null)
    };

    const hideDialog = () => {
        setSubmitted(false);
        setDataDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (data.title.trim()) {
            let _datas = [...datas];
            let _data = { ...data };


            if (data.id) {
                const formData = new FormData();
                formData.append('id', _data.id);
                formData.append('title', _data.title);
                formData.append('file', selectedFile);
                formData.append('description', _data.description);
                formData.append('category', category.notice_code);
                formData.append('tid', '1');
                formData.append('year_code', year.year_code);
                formData.append('institute_id', institute_id);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('updated_by', '1');
                let params = [];
                params.push(data.id);
                putData(getURL('CREATE', api, params), formData, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
                })


            } else {

                const formData = new FormData();
                formData.append('title', _data.title);
                formData.append('file', selectedFile);
                formData.append('description', _data.description);
                formData.append('category', category.notice_code);
                formData.append('tid', '1');
                formData.append('year_code', year.year_code);
                formData.append('institute_id', institute_id);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('updated_by', '1');


                postData(getURL('CREATE', api), formData, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Created', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Created', life: 3000 });

                })

            }

            setDatas(_datas);
            setDataDialog(false);
            setData(emptyModel);
        }
    };

    const edit = (info) => {
        const year = years.filter((val) => val.year_code === info.year_code)[0]
        setData({ ...info });
        setDataDialog(true);
        setYear(year)
    };

    const confirmDelete = (info) => {
        setData(info);
        setDeleteDialog(true);
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteData = () => {
        let _datas = datas.filter((val) => val.id !== data.id);
        let params = [];
        params.push(data.id);

        deleteRecord(getURL('CREATE', api, params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setDatas(_datas);
        setDeleteDialog(false);
        setData(emptyModel);
    };


    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedRecords = () => {
        let _datas = datas.filter((val) => !selectedRecords.includes(val));
        setDatas(_datas);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _data = { ...data };

        _data[`${name}`] = val;
        setData(_data);
    };

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>


            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.file_url} alt={rowData.title} className="shadow-2 border-round" style={{ width: '128px' }} />;
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage {title}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteData} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={datas} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" globalFilter={globalFilter} header={header}>
                    <Column field="category" header="Type" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="title" header="Title" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="description" header="Description" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="year_code" header="Year" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="file_url" header="File" style={{ minWidth: '20rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={dataDialog} style={{ width: '42rem' }} breakpoints={{ '960px': '85vw', '641px': '90vw' }} header={title} modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {data.avtar_url && <img src={data.avtar_url} alt={data.name} className="award-image block m-auto pb-3" />}

                <div className="field">
                    <label htmlFor="year" className="font-bold">
                        Academic Year
                    </label>
                    <Dropdown id="year" value={year} onChange={(e) => setYear(e.value)} options={years} placeholder="Select Year" required autoFocus optionLabel="year_code"
                        className={classNames({ 'p-invalid': submitted && !year })} />
                    {submitted && !year && <small className="p-error">Academic Year is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="category" className="font-bold">
                        Category
                    </label>
                    <Dropdown id="category" value={category} onChange={(e) => setCategory(e.value)} options={categories} placeholder="Select category" required autoFocus optionLabel="notice_code"
                        className={classNames({ 'p-invalid': submitted && !year })} />
                    {submitted && !category && <small className="p-error">Category is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="title" className="font-bold">
                        Title
                    </label>
                    <InputText id="title" value={data.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !data.title })} />
                    {submitted && !data.title && <small className="p-error">Title is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="description" className="font-bold">
                        Description
                    </label>
                    <InputText id="description" value={data.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus className={classNames({ 'p-invalid': submitted && !data.description })} />
                    {submitted && !data.description && <small className="p-error">Description is required.</small>}
                </div>


                <div className="field">
                    <label className="mb-3 font-bold">File chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="application/pdf" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>
            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {data && (
                        <span>
                            Are you sure you want to delete <b>{data.title}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {datas && <span>Are you sure you want to delete the selected records?</span>}
                </div>
            </Dialog>
        </div>
    );

}

export default NoticeLayout