import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { sourcePortalName } from '../../common/constant';
import { getProfile } from '../../common/auth.common';

const RequestLayout = ({ api, title, institute_id }) => {

    let emptyModel = {
        request_id: null,
        fname: "",
        lname: "",
        email: "",
        mob_no: "",
        dob: "",
        passout_year: "",
        job_designation: "",
        organization: "",
        approver_id: null,
        source_portal_name: "",
        institution_id: null,
        created_by: 1,
        is_active: "y",
        updated_by: 0

    }

    const [requests, setRequests] = useState([]);
    const [requestDialog, setRequestDialog] = useState(false);
    const [rejectDialog, setRejectDialog] = useState(false);
    const [approveDialog, setApproveDialog] = useState(false);
    const [request, setRequest] = useState(emptyModel);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET', api, [sourcePortalName()], true), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setRequests(resp.data.data);
            }
        }, (err) => {
            console.log(err)
        })
    }

    if (!requests) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setRequest(emptyModel);
        setSubmitted(false);
        setRequestDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRequestDialog(false);
    };

    const hideRejectDialog = () => {
        setRejectDialog(false);
    };

    const hideApproveDialog = () => {
        setApproveDialog(false);
    };

    const updateStatus = (status) => {

        if (request.request_id) {
            let _requests = [...requests];
            let _request = { ...request };
            _request.approver_id = JSON.parse(getProfile())["userId"];
            _request.req_status = status;
            _request.updated_by = JSON.parse(getProfile())["userId"];

            let params = [];
            params.push(request.request_id);
            params.push(status);

            putData(getURL('CREATE', api, params, true), _request, (resp) => {
                if (resp.status === 200 && resp.statusText === 'OK') {
                    fetchAll();
                    toast1.current.show({ severity: 'success', summary: 'Successful', detail: 'Request Updated', life: 3000 });
                }

            }, (err) => {
                toast1.current.show({ severity: 'error', summary: 'Error', detail: 'Request Not Update', life: 3000 });
            })
            setRequests(_requests);
            //setRequestDialog(false);
            setRequest(emptyModel);

            if (status === 'R') {
                setRejectDialog(false);
            } else if (status === 'A') {
                setApproveDialog(false);
            }
        }
    }

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (request.firstname.trim()) {
            let _requests = [...requests];
            let _request = { ...request };


            if (request.id) {
                const data = new FormData();
                data.append('id', _request.id)
                data.append('firstname', _request.firstname);
                data.append('lastname', _request.lastname);
                data.append('email', _request.email);
                data.append('mobile', _request.mobile);
                data.append('passout_year', _request.passout_year);
                data.append('address', _request.address);
                data.append('dob', _request.dob);
                data.append('designation', _request.designation);
                data.append('organization', _request.organization);
                data.append('tid', '0');
                data.append('institute_id', institute_id);
                data.append('is_active', 'Y');
                data.append('created_by', '1');

                let params = [];
                params.push(request.id);
                putData(getURL('CREATE', api, params), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Request Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Request Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();
                data.append('firstname', _request.firstname);
                data.append('lastname', _request.lastname);
                data.append('email', _request.email);
                data.append('mobile', _request.mobile);
                data.append('passout_year', _request.passout_year);
                //data.append('address', _request.address);
                data.append('dob', _request.dob);
                data.append('designation', _request.designation);
                data.append('organization', _request.organization);
                data.append('tid', '0');
                data.append('institute_id', institute_id);
                data.append('is_active', 'Y');
                data.append('created_by', '1');


                postData(getURL('CREATE', api), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Request Created', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Request Not Created', life: 3000 });

                })

            }

            setRequests(_requests);
            setRequestDialog(false);
            setRequest(emptyModel);
        }
    };

    const edit = (request, status) => {
        // alert("hello");
        setRequest({ ...request });
        if (status == "R") {
            setRejectDialog(true)
        } else {
            setApproveDialog(true);
        }
        updateStatus(status)
    };

    const confirmDelete = (request) => {
        setRequest(request);
        setRejectDialog(true);
    };


    const deleteInstitute = () => {
        let _requests = requests.filter((val) => val.request_id !== request.request_id);
        let params = [];
        params.push(request.request_id);
        deleteRecord(getURL('CREATE', api, params, true), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setRequests(_requests);
        setRejectDialog(false);
        setRequest(emptyModel);
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setApproveDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _requests = requests.filter((val) => !selectedRecords.includes(val));
        setRequests(_requests);
        setApproveDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _request = { ...request };

        _request[`${name}`] = val;
        setRequest(_request);
    };


    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>

            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        if (rowData.attachment_path)
            return <img src={rowData.attachment_path} alt={rowData.fname} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.req_status)
            //C->NEW
            //R->REJECTED
            //A->APPROVED

            return <div className=''>
                {(rowData.req_status == "C") ?
                    <div style={{ color: "blue", background: "white", fontFamily: "bold", textAlign: "center" }}>
                        New
                    </div>

                    : (rowData.req_status == "R") ? <div style={{ color: "red", background: "white", fontFamily: "bold", textAlign: "center" }}>
                        Rejected
                    </div> : <div style={{ color: "green", background: "white", fontFamily: "bold", textAlign: "center" }}>
                        Approved
                    </div>}
            </div>


    };

    const viewSectionTemplate = (rowData) => {
        return <Link to={`/institutions/${rowData.id}`}> <i className="fas fa-directions"></i> View/Edit Sections</Link>
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.req_status === "C" && <Button icon="pi pi-check" rounded outlined className="mr-2" onClick={() => edit(rowData, "A")} />}
                {rowData.req_status === "C" && <Button icon="pi pi-times" rounded outlined className="mr-2" onClick={() => edit(rowData, "R")} />}
                {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />*/}
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Alumni Requests</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const rejectRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideRejectDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={() => updateStatus("R")} />
        </React.Fragment>
    );
    const approveRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideApproveDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={() => updateStatus("A")} />
        </React.Fragment>
    );


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={null} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={requests} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="request_id" globalFilter={globalFilter} header={header}>

                    <Column field="fname" header="First Name" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="lname" header="Last Name" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="email" header="Email" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="mob_no" header="Mobile" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="job_designation" header="Designation" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="organization" header="Organization" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="dob" header="Birth Date" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="passout_year" header="Passing Year" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column header="Image" body={imageBodyTemplate}></Column>
                    <Column field="req_status" header="Status" sortable style={{ minWidth: '10rem' }} body={statusBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>


            <Dialog visible={rejectDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={rejectRecordDialogFooter} onHide={hideRejectDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {request && (
                        <span>
                            Are you sure you want to Reject Alumni request of  <b>{request.fname}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={approveDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={approveRecordsDialogFooter} onHide={hideApproveDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {request && <span>Are you sure you want to Approve the selected Alumni Request for ? <b>{request.fname}</b></span>}
                </div>
            </Dialog>
        </div>
    );

}

export default RequestLayout