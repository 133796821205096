import React from 'react'
import { AlumniEventLayout } from '../../layouts/alumni/alumni-event.layout'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'

export const AlumniEventsPage = () => {
    return (
        <>
            <PageHeaderComponent title="Alumni Events" />
            <div>
                <AlumniEventLayout api='ALUMNI_EVENT' title="Alumni Requests" institute_id={0} />
            </div>
        </>
    )
}
