
export const isAuthenticated = () => {
    return localStorage.getItem("isAuthenticated");
}

export const login = (profile) => {
    console.log(profile);
    localStorage.setItem("profile", JSON.stringify(profile));

}

export const setIsAuthenticated = (value) => {
    localStorage.setItem("isAuthenticated", value);
}

export const getProfile = () => {
    return localStorage.getItem("profile");
}

export const logout = () => {
    localStorage.removeItem("profile");
    localStorage.removeItem("isAuthenticated");
}


