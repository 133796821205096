import React, { useState, useEffect, useRef } from 'react';
import { deleteRecord, getData, postBody, putBody } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';


const AdmissionStudentLinksLayout = ({title, category}) => {
    let emptyLink = {
        title:'',
        link:'',
        category: category,
        institute: '',
        institute_id:'',
        tid:1,
        created_by:1,
        is_active:null
    }

    const [links, setLinks] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [currentCategory, setCurrentCategory] = useState([]);
    const [institute, setInstitute] = useState([]);
    const [linkDialog, setLinkDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [link, setLink] = useState(emptyLink);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        if (isPageAalreadyLoaded.current && category===currentCategory) return;
        setCurrentCategory(category)
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAllInstitutes()
        fetchAll();
    }, [category]);

    const fetchAllInstitutes = () => {
        getData(getURL('GET_INSTITUTE', 'INSTITUTE'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setInstitutes(resp.data.data);
            }
        }, (err) => {
            //console.log(err)
        })
    }
    const fetchAll = () => {
        let params = []
        params.push("category");
        params.push(category);
        getData(getURL('GET', 'ADMISSION_STUDENT_PORTAL_LINKS',params), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setLinks(resp.data.data);
            }
        }, (err) => {

        })
    }


    if (!links) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setLink(emptyLink);
        //setInstitute(null)
        setSubmitted(false);
        setLinkDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setLinkDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    
    const save = (toast) => {


        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (link) {
            let _links = [...links];
            let _link = { ...link };

            if (link.id) {
                const formData = new FormData();
                formData.append('id', _link.id);
                formData.append('title', _link.title);
                formData.append('url', _link.url);
                formData.append('category', category);
                formData.append('institute', institute.name);
                formData.append('institute_id', institute.id);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('tid', '1');

                let params = [];
                params.push(link.id);
                putBody(getURL('CREATE', 'ADMISSION_STUDENT_PORTAL_LINKS',params), formData, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Link Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Link not Updated', life: 3000 });
                })


            } else {

                const formData = new FormData();
                formData.append('title', _link.title);
                formData.append('url', _link.url);
                formData.append('category', category);
                formData.append('institute', institute.name);
                formData.append('institute_id', institute.id);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('tid', '1');

                postBody(getURL('CREATE', 'ADMISSION_STUDENT_PORTAL_LINKS'), formData, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Link Created', life: 3000 });

                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Link Not Created', life: 3000 });

                })

            }

            setLinks(_links);
            setLinkDialog(false);
            setLink(emptyLink);
        }
    };

    const edit = (link) => {
        console.log(link)
        const institute1 = institutes.filter((val) => val.id === link.institute_id)[0]
        console.log(institute1)
        setLink({ ...link });
        setLinkDialog(true);
        setInstitute(institute1)
    };

    const confirmDelete = (link) => {
        setLink(link);
        setDeleteDialog(true);
    };


    const deleteLink = () => {
        let _links = links.filter((val) => val.id !== link.id);
        let params = [];
        params.push(link.id);
        deleteRecord(getURL('CREATE', 'ADMISSION_STUDENT_PORTAL_LINKS', params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
             //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
           // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setLinks(_links);
        setDeleteDialog(false);
        setLink(emptyLink);

    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < links.length; i++) {
            if (links[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _links = links.filter((val) => !selectedRecords.includes(val));
        setLinks(_links);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, title) => {
        const val = (e.target && e.target.value) || '';
        let _link = { ...link };

        _link[`${title}`] = val;

        setLink(_link);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>

                
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage {title}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteLink} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );

    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={links} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} rtis" globalFilter={globalFilter} header={header}>
                    
                    <Column field="title" header="Title" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="institute" header="Institute" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="url" header="Links" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={linkDialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={title + " Details"} modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                
                <div className="field">
                    <label htmlFor="title" className="font-bold">
                        Title
                    </label>
                    <InputText id="title" value={link.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !link.title })} />
                    {submitted && !link.title && <small className="p-error">Title is required.</small>}
                </div>
                
                <div className="field">
                    <label htmlFor="institute" className="font-bold">
                        Institute
                    </label>
                    <Dropdown id="institute" value={institute} onChange={(e) => setInstitute(e.value)} options={institutes} placeholder="Select institute" required autoFocus optionLabel="name"
                        className={classNames({ 'p-invalid': submitted && !institute })} />
                    {submitted && !institute && <small className="p-error">Institute is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="url" className="font-bold">
                        Link
                    </label>
                    <InputText id="url" value={link.url} onChange={(e) => onInputChange(e, 'url')} required autoFocus className={classNames({ 'p-invalid': submitted && !link.url })} />
                    {submitted && !link.link && <small className="p-error">Link is required.</small>}
                </div>

            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {link && (
                        <span>
                            Are you sure you want to delete <b>{link.title}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {selectedRecords && <span>Are you sure you want to delete the selected RTIs?</span>}
                </div>
            </Dialog>
        </div>
    );

}

export default AdmissionStudentLinksLayout