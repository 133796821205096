import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import SectionCKEditorLayout from '../../layouts/sections/section-ckeditor.layout'

export const ContactusPage = () => {
    return (
        <>

            <PageHeaderComponent title="Contact us" />
            <div>
            <SectionCKEditorLayout api='SCHOOL_CONTACT' title='Contact' institute_id={0} />
            </div>

        </>
    )
}
