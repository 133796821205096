import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { getData, postBody } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import Editor from '../../components/editor/Editor';

const ActivityLayout = ({ title, institute_id }) => {
    let emptyModel = {
        title: '',
        content: '',
        logo: null,
        tid: 1,
        institute_id: 1,
        created_by: 1,
        is_active: null
    }

    const [datas, setDatas] = useState([]);
    const [dataDialog, setDataDialog] = useState(false);
    const [content, setContent] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [data, setData] = useState(emptyModel);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET_SCHOOL_ACTIVITY', 'SCHOOL_ACTIVITY'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setDatas(resp.data.data);
            }
        }, (err) => {
            //console.log(err)
        })
    }

    if (!datas) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setData(emptyModel);
        setSubmitted(false);
        setDataDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setDataDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (data.title.trim()) {
            let _datas = [...datas];
            let _data = { ...data };


            if (data.id) {
                const data = new FormData();
                data.append('id', _data.id)
                data.append('title', _data.title);
                data.append('content', content);
                data.append('tid', '1');
                data.append('institute_id', '1');
                data.append('created_by', '1');
                data.append('is_active', '1');
                data.append('updated_by', '1');

                postBody(getURL('CREATE_SCHOOL_ACTIVITY', 'SCHOOL_ACTIVITY'), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();
                data.append('title', _data.title);
                data.append('content', content);
                data.append('tid', '1');
                data.append('institute_id', '1');
                data.append('created_by', '1');
                data.append('is_active', '1');
                data.append('updated_by', '1');


                postBody(getURL('CREATE_SCHOOL_ACTIVITY', 'SCHOOL_ACTIVITY'), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Created', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Created', life: 3000 });

                })

            }

            setDatas(_datas);
            setDataDialog(false);
            setData(emptyModel);
        }
    };

    const edit = (info) => {
        setData({ ...info });
        setDataDialog(true);
    };

    const confirmDelete = (info) => {
        setData(info);
        setDeleteDialog(true);
    };


    const deleteData = () => {
        let _datas = datas.filter((val) => val.id !== data.id);
        setDatas(_datas);
        setDeleteDialog(false);
        setData(emptyModel);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _datas = datas.filter((val) => !selectedRecords.includes(val));
        setDatas(_datas);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _data = { ...data };

        _data[`${name}`] = val;
        setData(_data);
    };


    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>


            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const stringToHtmlBodyTemplate = (rowData) => {
        return parse(rowData.content);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Section Data</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteData} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={datas.length === 0 ? leftToolbarTemplate : null} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={datas} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" globalFilter={globalFilter} header={header}>

                    <Column field="title" header="Title" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="content" header="Content" body={stringToHtmlBodyTemplate} sortable style={{ minWidth: '30rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={dataDialog} style={{ width: '52rem' }} breakpoints={{ '960px': '85vw', '641px': '90vw' }} header="School Activity Details" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="title" className="font-bold">
                        Title
                    </label>
                    <InputText id="title" value={data.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !data.title })} />
                    {submitted && !data.title && <small className="p-error">Title is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="content" className="font-bold">
                        Content
                    </label>
                    <Editor id="content" data={data.content} setdata={setContent} required autoFocus className={classNames({ 'p-invalid': submitted && !data.content })} />

                    {submitted && !data.content && <small className="p-error">Content is required.</small>}
                </div>

            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {data && (
                        <span>
                            Are you sure you want to delete <b>{data.title}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {datas && <span>Are you sure you want to delete the selected records?</span>}
                </div>
            </Dialog>
        </div>
    );

}

export default ActivityLayout