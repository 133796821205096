import React from 'react'

export const PageHeaderComponent = ({ title }) => {
    return (
        <div className="d-sm-flex align-items-center justify-content-between mb-4">

            <h5 className="mb-0 text-gray-800"> <i className="fas fa-file"></i> {title}</h5>
        </div>
    )
}
