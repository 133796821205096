import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import GalleryLayout from '../../layouts/sections/gallery.layout'

export const GalleryPage = () => {
    return (
        <>

            <PageHeaderComponent title="Gallery" />
            <div>
                <GalleryLayout api='GALLERY' title='Gallery' institute_id={0}/>
            </div>

        </>
    )
}
