import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';

import './trustees.layout.scss';
import Editor from '../../components/editor/Editor';


export const TrusteesLayout = () => {

    let emptyTrustee = {
        id: null,
        name: '',
        avtar_url: '',
        position: '',
        designation: '',
        info: '',
        more_info: '',
        created_at: null
    };

    const [content, setContent] = useState('')
    const [trustees, setTrustees] = useState([]);
    const [trusteeDialog, setTrusteeDialog] = useState(false);
    const [deleteTrusteeDialog, setDeleteTrusteeDialog] = useState(false);
    const [deleteTrusteesDialog, setDeleteTrusteesDialog] = useState(false);
    const [trustee, setTrustee] = useState(emptyTrustee);
    const [selectedTrustees, setSelectedTrustees] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchALlTrustees();
    }, []);

    const fetchALlTrustees = () => {
        getData(getURL('GET_ALL_TRUSTEES', 'TRUSTEE'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setTrustees(resp.data.data);

            }

        }, (err) => {

        })
    }


    if (!trustees) {
        return <div>Loading data...</div>;
    }


    const openNew = () => {
        setTrustee(emptyTrustee);
        setSubmitted(false);
        setTrusteeDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setTrusteeDialog(false);
    };

    const hideDeleteTrusteeDialog = () => {
        setDeleteTrusteeDialog(false);
    };

    const hideDeleteTrusteesDialog = () => {
        setDeleteTrusteesDialog(false);
    };

    const saveTrustee = (toast) => {


        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (trustee.name.trim()) {
            let _trustees = [...trustees];
            let _trustee = { ...trustee };

            if (trustee.id) {
                const formData = new FormData();
                formData.append('id', _trustee.id);
                formData.append('avtar', selectedFile);
                formData.append('name', _trustee.name);
                formData.append('designation', _trustee.designation);
                formData.append('position', _trustee.position);
                formData.append('info', _trustee.info);
                formData.append('more_info', content);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('tid', '1');

                let params = [];
                params.push(trustee.id);
                putData(getURL('CREATE_TRUSTEE', 'TRUSTEE',params), formData, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchALlTrustees();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Trustee Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Trustee Not Update', life: 3000 });
                })


            } else {

                const formData = new FormData();
                formData.append('avtar', selectedFile);
                formData.append('name', _trustee.name);
                formData.append('designation', _trustee.designation);
                formData.append('position', _trustee.position);
                formData.append('info', _trustee.info);
                formData.append('more_info', content);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('tid', '1');


                postData(getURL('CREATE_TRUSTEE', 'TRUSTEE'), formData, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchALlTrustees();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Trustee Created', life: 3000 });

                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Trustee Not Created', life: 3000 });

                })

            }

            setTrustees(_trustees);
            setTrusteeDialog(false);
            setTrustee(emptyTrustee);
        }
    };

    const editTrustee = (trustee) => {
        setContent(trustee.more_info)
        setTrustee({ ...trustee });
        setTrusteeDialog(true);

    };

    const confirmDeleteTrustee = (trustee) => {
        setTrustee(trustee);
        setDeleteTrusteeDialog(true);
    };


    const deleteTrustee = () => {
        let _trustees = trustees.filter((val) => val.id !== trustee.id);
        let params = [];
            params.push(trustee.id);
        deleteRecord(getURL('CREATE_TRUSTEE', 'TRUSTEE', params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchALlTrustees();
             //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
           // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setTrustees(_trustees);
        setDeleteTrusteeDialog(false);
        setTrustee(emptyTrustee);

    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < trustees.length; i++) {
            if (trustees[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteTrusteesDialog(true);
    };

    const deleteSelectedTrustees = () => {
        let _trustees = trustees.filter((val) => !selectedTrustees.includes(val));

        setTrustees(_trustees);
        setDeleteTrusteesDialog(false);
        setSelectedTrustees(null);

    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _trustee = { ...trustee };

        _trustee[`${name}`] = val;

        setTrustee(_trustee);
    };



    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const stringToHtmlBodyTemplate = (rowData) =>{
        return parse(rowData.more_info);
    }
    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.avtar_url} alt={rowData.image} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };



    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editTrustee(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteTrustee(rowData)} />
            </React.Fragment>
        );
    };


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Trustees</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const trusteeDialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => saveTrustee(toast1)} />
        </React.Fragment>
    );
    const deleteTrusteeDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteTrusteeDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteTrustee} />
        </React.Fragment>
    );
    const deleteTrusteesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteTrusteesDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedTrustees} />
        </React.Fragment>
    );


    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };



    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={trustees} selection={selectedTrustees} onSelectionChange={(e) => setSelectedTrustees(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} trustees" globalFilter={globalFilter} header={header}>
                    
                    <Column field="id" header="ID" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="name" header="Name" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="designation" header="Designation" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="image" header="Image" body={imageBodyTemplate}></Column>
                    <Column field="position" header="Position" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="info" header="info" style={{ minWidth: '12rem' }}></Column>
                    <Column field="content" header="Description" body={stringToHtmlBodyTemplate} sortable style={{ minWidth: '16rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={trusteeDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Trustee Details" modal className="p-fluid" footer={trusteeDialogFooter} onHide={hideDialog}>
                {trustee.avtar_url && <img src={trustee.avtar_url} alt={trustee.name} className="trustee-image block m-auto pb-3" />}
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={trustee.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !trustee.name })} />
                    {submitted && !trustee.name && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="designation" className="font-bold">
                        Designation
                    </label>
                    <InputText id="designation" value={trustee.designation} onChange={(e) => onInputChange(e, 'designation')} required autoFocus className={classNames({ 'p-invalid': submitted && !trustee.designation })} />
                    {submitted && !trustee.designation && <small className="p-error">Designation is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="position" className="font-bold">
                        Position
                    </label>
                    <InputText id="position" value={trustee.position} onChange={(e) => onInputChange(e, 'position')} required autoFocus className={classNames({ 'p-invalid': submitted && !trustee.position })} />
                    {submitted && !trustee.position && <small className="p-error">Position is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="info" className="font-bold">
                        Information
                    </label>
                    <InputText id="info" value={trustee.info} onChange={(e) => onInputChange(e, 'info')} required autoFocus className={classNames({ 'p-invalid': submitted && !trustee.info })} />
                    {submitted && !trustee.info && <small className="p-error">Information is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="info" className="font-bold">
                    More Information
                    </label>
                    <Editor id="info" data={trustee.more_info} setdata={setContent} required autoFocus className={classNames({ 'p-invalid': submitted && !trustee.more_info })}/>
                    
                    {submitted && !trustee.more_info && <small className="p-error">Information is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>


            </Dialog>

            <Dialog visible={deleteTrusteeDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteTrusteeDialogFooter} onHide={hideDeleteTrusteeDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {trustee && (
                        <span>
                            Are you sure you want to delete <b>{trustee.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteTrusteesDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteTrusteesDialogFooter} onHide={hideDeleteTrusteesDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {trustee && <span>Are you sure you want to delete the selected Truestee?</span>}
                </div>
            </Dialog>
        </div>
    );
}
