import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import RTILayout from '../../layouts/rti/rti.layout'

const RTIPage = () => {
  return (
    <>
    <PageHeaderComponent title="Right To Information"/>
    <div>
        <RTILayout/>
    </div>
    </>
  )
}

export default RTIPage