import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import RequestLayout from '../../layouts/alumni/request.layout'

const AlumniRequestPage = () => {
  return (
    <>
        <PageHeaderComponent title="Alumni Requests"/>
        <div>
            <RequestLayout api='ALUMNI' title="Alumni Requests" institute_id={0}/>
        </div>
    </>
  )
}

export default AlumniRequestPage