import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const Editor = (props) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={props.data}
            onReady={editor => {
                // You can store the "editor" and use when it is needed.
                //console.log( 'Editor is ready to use!', editor );
                editor.editing.view.change((writer) => {
                    writer.setStyle(
                        "min-height",
                        "150px",
                        editor.editing.view.document.getRoot()
                    );
                });
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                //console.log( { event, editor, data } );
                props.setdata(data);
            }}
            onBlur={(event, editor) => {
                //console.log( 'Blur.', editor );
            }}
            onFocus={(event, editor) => {
                //console.log( 'Focus.', editor );
            }}
        />
    )
}

export default Editor