import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { DashBoardPage } from '../pages/dashboard/dashboard.page';
import LinksPage from '../pages/footer-menu/links.page';
import RTIPage from '../pages/footer-menu/rti.page';
import MediaTalkPage from '../pages/footer-menu/media-talk.page'
import YashogathaPage from '../pages/footer-menu/yasho-gatha.page'
import { AwardsPage } from '../pages/header-menu/awards.page';
import { ContactusPage } from '../pages/header-menu/contactus-page';
import { GalleryPage } from '../pages/header-menu/gallery.page';
import { InstitutionsPage } from '../pages/header-menu/institutions.page';
import { TrusteesPage } from '../pages/header-menu/trustees.page';
import StatisticPage from '../pages/footer-menu/statistic-management.page';
import GrievancePage from '../pages/grievances/grievance.page'
import { InstituteSectionLayout } from '../layouts/institute/institute-sections/institute-section.layout';
import InstituteLayout from '../layouts/institute/institute.layout'
import NoticePage from "../pages/notice/notices.page"
import { LoginPage } from '../pages/login/Login.page';
import { WrapperPage } from '../Wrapper.page';
import { PrivateRoute } from '../middleware/private-route.middleware';
import AlumniRequestPage from '../pages/alumini/requests.page';
import UsersPage from '../pages/users/users.page';
import StudentAchievementPage from '../pages/sections/student-achievement.page';
import ActivityPage from '../pages/sections/activity.page'
import AdmissionStudentLinkPage from '../pages/admission-student-link/admission-student-link.page';
import { AlumniAnnouncementPage } from '../pages/alumini/announcement.page';
import { AlumniHomeSliderPage } from '../pages/alumini/alumni-home-slider.page';
import { AlumniEventsPage } from '../pages/alumini/alumni-events.page';
import { AlumniGalleryPage } from '../pages/alumini/alumni-gallery.page';
import { AlumniGalleryContainerLayout } from '../layouts/alumni/alumni-gallery-container.layout';
import { AlumniGalleryPhotoLayout } from '../layouts/alumni/alumni-gallery-photo.layout';
import { AlumniGalleryPhotoPage } from '../pages/alumini/alumni-gallery-photo.page';
import { AlumniContactPage } from '../pages/alumini/alumni-contact.page';


export const RoutesComponent = () => {
    return (
        <Routes>
            <Route element={<LoginPage />} path="/login" />
            <Route element={<LoginPage />} path="/" />


            <Route element={<PrivateRoute />}>
                <Route element={<WrapperPage />} path="/wrapperPage">
                    <Route element={<TrusteesPage />} path="/wrapperPage/trustees" />

                    <Route element={<TrusteesPage />} path="/wrapperPage/trustees" />
                    <Route element={<InstitutionsPage />} path="/wrapperPage/institutions">
                        <Route element={<InstituteLayout />} path="/wrapperPage/institutions" />
                        <Route element={<InstituteSectionLayout />} path="/wrapperPage/institutions/:id" />
                    </Route>
                    <Route element={<AwardsPage />} path="/wrapperPage/awards" />
                    <Route element={<GalleryPage />} path="/wrapperPage/gallery" />
                    <Route element={<ContactusPage />} path="/wrapperPage/contact" />
                    <Route element={<RTIPage />} path="/wrapperPage/rti" />
                    <Route element={<MediaTalkPage />} path="/wrapperPage/media" />
                    <Route element={<LinksPage />} path="/wrapperPage/links" />
                    <Route element={<ActivityPage />} path="/wrapperPage/stats" />
                    <Route element={<LinksPage />} path="/wrapperPage/links" />
                    <Route element={<StudentAchievementPage />} path="/wrapperPage/stats" />
                    <Route element={<StatisticPage />} path="/wrapperPage/stats1" />
                    <Route element={<YashogathaPage />} path="/wrapperPage/yashogatha" />
                    <Route element={<GrievancePage />} path="/wrapperPage/grievances" />
                    <Route element={<NoticePage />} path="/wrapperPage/notices" />
                    <Route element={<AlumniRequestPage />} path="/wrapperPage/alumni" />
                    <Route element={<AlumniAnnouncementPage />} path="/wrapperPage/alumni-notifications" />
                    <Route element={<AlumniHomeSliderPage />} path="/wrapperPage/alumni-slider" />
                    <Route element={<AlumniEventsPage />} path="/wrapperPage/alumni-events" />
                    <Route element={<AlumniGalleryPage />} path="/wrapperPage/alumni-gallery" />
                    <Route element={<AlumniGalleryContainerLayout />} path="/wrapperPage/alumni-gallery">
                        <Route element={<AlumniGalleryPage />} path="/wrapperPage/alumni-gallery" />
                        <Route element={<AlumniGalleryPhotoPage />} path="/wrapperPage/alumni-gallery/:id" />
                    </Route>
                    <Route element={<AlumniContactPage />} path="/wrapperPage/alumni-contact" />
                    <Route element={<UsersPage />} path="/wrapperPage/users" />
                    <Route element={<AdmissionStudentLinkPage title="Student portal Links" category="STUDENT-PORTAL" />} path="/wrapperPage/student-portal-links" />
                    <Route element={<AdmissionStudentLinkPage title="Admission Links" category="ADMISSION-PORTAL" />} path="/wrapperPage/admission-links" />
                </Route>
            </Route>
        </Routes >
    )
}
