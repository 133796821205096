import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import AchievementLayout from '../../layouts/sections/achievement.layout'

const StudentAchievementPage = () => {
    return(
        <>
            <PageHeaderComponent title="Student Achievement"/>
            <div>
                <AchievementLayout isTeacher={false} />
            </div>
        </>
    )
}

export default StudentAchievementPage