import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';

import '../awards/awards.layout.scss';
import Editor from '../../components/editor/Editor';

const AchievementLayout = ({ api, isTeacher, title, institute_id }) => {
    let emptyModel = {
        id: '',
        achiever_name: '',
        achievement: '',
        achievement_desc: '',
        achievement_type_code: '',
        year_code: '',
        is_tearcher: isTeacher,
        institute_id: institute_id,
        avtar: null,
        tid: 1,
        is_active: null,
        created_at: null
    }

    const [isTeacherChange, setIsTeacherChange] = useState(null);
    const [years, setYears] = useState([]);
    const [types, setTypes] = useState([]);
    const [year, setYear] = useState(null)
    const [type, setType] = useState(null)
    const [achievements, setAchievements] = useState([]);
    const [achievementDialog, setAchievementDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [content, setContent] = useState(null);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [achievement, setAchievement] = useState(emptyModel);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        if (isPageAalreadyLoaded.current && isTeacherChange === isTeacher) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        setIsTeacherChange(isTeacher)
        fetchYears()
        fetchAchievementTypes()
        fetchAll();
    }, [isTeacher]);

    const fetchYears = () => {
        getData(getURL('GET_ACADEMIC_YEAR', 'ACADEMIC_YEARS'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setYears(resp.data.data);
            }
        }, (err) => {

        })
    }

    const fetchAchievementTypes = () => {
        getData(getURL('GET_ACHIEVEMENT_TYPE', 'ACHIEVEMENT_TYPE'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setTypes(resp.data.data);
            }
        }, (err) => {

        })
    }

    const fetchAll = () => {
        let params = [];
        params.push("institute");
        params.push(institute_id);
        const url = getURL('GET', api, params);
        getData(url, (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setAchievements(resp.data.data);
            }
        }, (err) => {
            setAchievements([])
        })
    }


    if (!achievements) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setAchievement(emptyModel);
        setSubmitted(false);
        setAchievementDialog(true);
        setYear(null)
        setType(null)
    };

    const hideDialog = () => {
        setSubmitted(false);
        setAchievementDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };



    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (achievement.achiever_name.trim()) {
            let _achievements = [...achievements];
            let _achievement = { ...achievement };

            if (achievement.id) {
                const data = new FormData();
                data.append('id', _achievement.id);
                data.append('achiever_name', _achievement.achiever_name);
                data.append('achievement', _achievement.achievement);
                data.append('desc', content);
                data.append('achievement_type_code', type.achievement_code);
                data.append('year_code', year.year_code);
                data.append('is_tearcher', isTeacher);
                data.append('institute_id', institute_id);
                data.append('tid', '1');
                data.append('created_by', '1');
                data.append('is_active', 'Y');
                data.append('avtar', selectedFile);


                let params = [];
                params.push(achievement.id);
                putData(getURL('CREATE', api, params), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();
                data.append('achiever_name', _achievement.achiever_name);
                data.append('achievement', _achievement.achievement);
                data.append('desc', content);
                data.append('achievement_type_code', type.achievement_code);
                data.append('year_code', year.year_code);
                data.append('is_tearcher', isTeacher);
                data.append('institute_id', institute_id);
                data.append('tid', '1');
                data.append('created_by', '1');
                data.append('is_active', 'Y');
                data.append('avtar', selectedFile);


                postData(getURL('CREATE', api), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Award Created', life: 3000 });

                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Award Not Created', life: 3000 });

                })

            }

            setAchievements(_achievements);
            setAchievementDialog(false);
            setAchievement(emptyModel);
        }
    };

    const edit = (achievement) => {
        console.log(achievement)
        const type = types.filter((val) => val.achievement_code === achievement.achievement_type_code)[0]
        const year = years.filter((val) => val.year_code === achievement.year_code)[0]
        setAchievement({ ...achievement });
        setContent(achievement.achievement_desc)
        setAchievementDialog(true);
        setYear(year)
        setType(type)

    };

    const confirmDelete = (achievement) => {
        setAchievement(achievement);
        setDeleteDialog(true);
    };


    const deleteData = () => {
        let _achievements = achievements.filter((val) => val.id !== achievement.id);
        let params = [];
        params.push(achievement.id);

        deleteRecord(getURL('CREATE', api, params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })

        setAchievements(_achievements);
        setDeleteDialog(false);
        setAchievement(emptyModel);

    };



    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _achievements = achievements.filter((val) => !selectedRecords.includes(val));
        setAchievements(_achievements);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _achievement = { ...achievement };

        _achievement[`${name}`] = val;

        setAchievement(_achievement);
    };

    const onSelectChange = (val, name) => {
        let _achievement = { ...achievement };
        console.log(_achievement)
        _achievement[`${name}`] = val[`${name}`];

        setAchievement(_achievement);
        console.log(achievement)
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>


            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.file_url} alt={rowData.name} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const stringToHtmlBodyTemplate = (rowData) => {
        return parse(rowData.achievement_desc);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage {title}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteData} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={achievements} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} achievements" globalFilter={globalFilter} header={header}>

                    <Column field="achiever_name" header="Name" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="year_code" header="Year" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="achievement_type_code" header="Type" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="achievement" header="Achievement" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="achievement_desc" header="Description" body={stringToHtmlBodyTemplate} sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="file_url" header="Image" body={imageBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={achievementDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={title} modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {achievement.file_url && <img src={achievement.file_url} alt={achievement.name} className="award-image block m-auto pb-3" />}

                <div className="field">
                    <label htmlFor="year" className="font-bold">
                        Academic Year
                    </label>
                    <Dropdown id="year" value={year} onChange={(e) => setYear(e.value)} options={years} placeholder="Select Year" required autoFocus optionLabel="year_code"
                        className={classNames({ 'p-invalid': submitted && !year })} />
                    {submitted && !year && <small className="p-error">Academic Year is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="type" className="font-bold">
                        Achievement Type
                    </label>
                    <Dropdown id="type" value={type} onChange={(e) => setType(e.value)} options={types} placeholder="Select Type" required optionLabel="achievement_code"
                        className={classNames({ 'p-invalid': submitted && !type })} />
                    {submitted && !type && <small className="p-error">Type is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={achievement.achiever_name} onChange={(e) => onInputChange(e, 'achiever_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !achievement.achiever_name })} />
                    {submitted && !achievement.achiever_name && <small className="p-error">Name is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="achievement" className="font-bold">
                        Achievement
                    </label>
                    <InputText id="achievement" value={achievement.achievement} onChange={(e) => onInputChange(e, 'achievement')} required autoFocus className={classNames({ 'p-invalid': submitted && !achievement.achievement })} />
                    {submitted && !achievement.achievement && <small className="p-error">Achievement is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="info" className="font-bold">
                        Description
                    </label>
                    <Editor id="info" data={achievement.achievement_desc} setdata={setContent} required autoFocus className={classNames({ 'p-invalid': submitted && !achievement.achievement_desc })} />

                    {submitted && !achievement.achievement_desc && <small className="p-error">Description is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>


            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {achievement && (
                        <span>
                            Are you sure you want to delete <b>{achievement.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {selectedRecords && <span>Are you sure you want to delete the selected records?</span>}
                </div>
            </Dialog>
        </div>
    );

}

export default AchievementLayout