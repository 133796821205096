import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import { AlumniAnnouncementLayout } from '../../layouts/alumni/alumni-announcement.layout'

export const AlumniAnnouncementPage = () => {
    return (
        <>
            <PageHeaderComponent title="Alumni Notifications" />
            <div>
                <AlumniAnnouncementLayout api='ALUMNI_NOTIFICATION' title="Alumni Requests" institute_id={0} />
            </div>
        </>
    )
}
