import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import AlumniContactLayout from '../../layouts/alumni/alumni-contact.layout'

export const AlumniContactPage = () => {
    return (
        <>
            <PageHeaderComponent title="Alumni Contacts" />
            <div>
                <AlumniContactLayout api='ALUMNI_CONTACT' title="Alumni Contact" institute_id={0} />
            </div>
        </>
    )
}
