import React, { useState } from "react";
import ReactDOM from "react-dom";

import { useNavigate } from 'react-router-dom';
import { getProfile, isAuthenticated, login, logout, setIsAuthenticated } from "../../common/auth.common";
import { postData, getData } from "../../api/AxisConfig";
import { getURL } from "../../api/URL";

import Logo from '../../assets/images/LOGO.png'

import "./login.scss";

export const LoginPage = () => {
    const navigate = useNavigate();

    const isLoggedIn = isAuthenticated();

    // React States
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // User Login info

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        var { uname, pass } = document.forms[0];

        // Find user login info
        // const userData = database.find((user) => user.username === uname.value);

        if (uname && pass) {
            //  const formData = new FormData();
            // formData.append('username', uname);
            // formData.append('password', pass);

            const data = {
                username: uname.value,
                password: pass.value
            };


            //call login service
            postData(getURL("LOGIN", "APP_COMMON"), data, (resp) => {

                if (resp.status === 200) {
                    let userData = resp.data;

                    const params = [userData.data.user_profile_id];

                    getData(getURL("MY_PROFILE", "APP_COMMON", params), (resp) => {
                        if (resp.status === 200) {

                            const user = resp.data.data;
                            //console.error(user);
                            let profile = {
                                "firstname": user.firstname,
                                "lastname": user.lastname,
                                "email": user.email,
                                "mobile": user.mobile,
                                "image": user.avtar_url,
                                "userId": userData.data.id,
                            }

                            login(profile);
                            setIsAuthenticated(true);
                            navigate('/wrapperPage');
                        }

                    },
                        (error) => {
                            //console.error("login error::" + error);
                        })



                }


            }, (error) => {
                //console.error(error);
                const resp = error.response;

                if (resp.status === 400) {
                    setErrorMessages({ name: "uname", message: resp.data.error });
                }
                if (resp.status === 401) {
                    setErrorMessages({ name: "pass", message: resp.data.error });
                } else {

                }

            });
        } else {
            setErrorMessages({ name: "pass", message: "Please provide username and password" });
        }


    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    // JSX code for login form
    const renderForm = (
        <div className="form">
            <center><img src={Logo} className="logo" /></center>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Username </label>
                    <input type="text" name="uname" autoComplete="off" />
                    {renderErrorMessage("uname")}
                </div>
                <div className="input-container">
                    <label>Password </label>
                    <input type="password" name="pass" autoComplete="off" />
                    {renderErrorMessage("pass")}
                </div>
                <div className="button-container">
                    <input type="submit" />
                </div>
            </form>
        </div>
    );

    return (
        <div className="login">
            <div className="app">
                <div className="login-form">
                    <div className="title">HM Portal |Login</div>
                    {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
                </div>
            </div>
        </div>
    );
}
