import React, { useState, useEffect, useRef } from 'react';
import { deleteRecord, getData, postData, putBody, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';

const UsersLayout = ({ api, title}) => {
    
    let emptyModel = {
        id: null,
        firstname:'',
        lastname: '',
        email: '',
        mobile:'',
        tid: 1,
        institute_id:0,
        created_by: 1,
        is_active: null
    }

    const [users, setUsers] = useState([]);
    const [userDialog, setUserDialog] = useState(false);
    const [resetPassDialog, setResetPassDialog] = useState(false);
    const [instituteId, setInstituteId] = useState(0);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [user, setUser] = useState(emptyModel);
    const [password, setPassword] = useState(null)
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET', api), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setUsers(resp.data.data);
            }
        }, (err) => {
            console.log(err)
        })
    }

    if (!users) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setUser(emptyModel);
        setSubmitted(false);
        setUserDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
        setResetPassDialog(false)
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    const resetPassword = (toast) =>{
        setSubmitted(true);
        if(user.email.trim()){
            let _users = [...users];
            let _user = { ...user };

            const data = new FormData();
            data.append('password', password);
            data.append('username', _user.email);

            
            putBody(getURL('CHANGE_PASSWORD', api), data, (resp) => {
                if (resp.status === 200 && resp.statusText === 'OK') {
                   // fetchAll();
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Password Updated', life: 3000 });
                }
            }, (err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Password Not Update', life: 3000 });
            })

            setPassword(null)
            setUsers(_users);
            setResetPassDialog(false);
            setUser(emptyModel);
        }
    }

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (user.firstname.trim()) {
            let _users = [...users];
            let _user = { ...user };


            if (user.id) {
                const data = new FormData();
                data.append('id', _user.id)
                data.append('firstname', _user.firstname);
                data.append('lastname', _user.lastname);
                data.append('email', _user.email);
                data.append('mobile', _user.mobile);
                data.append('avtar',selectedFile)
                data.append('tid', '0');
                data.append('institute_id', instituteId);
                data.append('is_active', 'Y');
                data.append('created_by', '1');

                let params = [];
                params.push(user.id);
                putData(getURL('CREATE', api, params), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Request Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Request Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();
                data.append('firstname', _user.firstname);
                data.append('lastname', _user.lastname);
                data.append('email', _user.email);
                data.append('mobile', _user.mobile);
                data.append('avtar',selectedFile)
                data.append('tid', '0');
                data.append('institute_id', instituteId);
                data.append('is_active', 'Y');
                data.append('created_by', '1');


                postData(getURL('CREATE', api), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Request Created', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Request Not Created', life: 3000 });

                })

            }

            setUsers(_users);
            setUserDialog(false);
            setUser(emptyModel);
        }
    };

    const updatePassword = (model) =>{
        setUser({ ...model });
        setResetPassDialog(true);
    }

    const edit = (model) => {
        setUser({ ...model });
        setUserDialog(true);
    };

    const confirmDelete = (model) => {
        setUser(model);
        setDeleteDialog(true);
    };


    const deleteModel = () => {
        let _users = users.filter((val) => val.id !== user.id);
        let params = [];
        params.push(user.id);
        deleteRecord(getURL('CREATE', api, params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
             //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
           // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setUsers(_users);
        setDeleteDialog(false);
        setUser(emptyModel);
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _users = users.filter((val) => !selectedRecords.includes(val));
        setUsers(_users);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };

        _user[`${name}`] = val;
        setUser(_user);
    };

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>

            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        if (rowData.avtar_url)
            return <img src={rowData.avtar_url} alt={rowData.firstname} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined className="mr-2" severity="danger" onClick={() => confirmDelete(rowData)} />
                <Button icon="fa fa-key" rounded outlined  onClick={() => updatePassword(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Alumni Requests</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );

    const PasswordDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => resetPassword(toast1)} />
        </React.Fragment>
    );

    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteModel} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={users} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" globalFilter={globalFilter} header={header}>
                    
                    <Column field="firstname" header="First Name" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="lastname" header="Last Name" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="email" header="Email" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="mobile" header="Mobile" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="avtar_url" header="Photo" body={imageBodyTemplate}></Column>
                    
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>
                </DataTable>
            </div>
      
            <Dialog visible={userDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="User Add/Edit" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {user.avtar_url && <img src={user.avtar_url} alt={user.firstname} className="award-image block m-auto pb-3" />}
                <div className="field">
                    <label htmlFor="firstname" className="font-bold">
                        First Name
                    </label>
                    <InputText id="firstname" value={user.firstname} onChange={(e) => onInputChange(e, 'firstname')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.firstname })} />
                    {submitted && !user.firstname && <small className="p-error">First Name is required.</small>}
                </div>
                
                <div className="field">
                    <label htmlFor="lastname" className="font-bold">
                        Last Name
                    </label>
                    <InputText id="lastname" value={user.lastname} onChange={(e) => onInputChange(e, 'lastname')} required className={classNames({ 'p-invalid': submitted && !user.lastname })} />
                    {submitted && !user.lastname && <small className="p-error">Last Name is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="email" className="font-bold">
                        Email
                    </label>
                    <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !user.email })} />
                    {submitted && !user.email && <small className="p-error">Email is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="mobile" className="font-bold">
                        Mobile
                    </label>
                    <InputText id="mobile" value={user.mobile} onChange={(e) => onInputChange(e, 'mobile')} required className={classNames({ 'p-invalid': submitted && !user.mobile })} />
                    {submitted && !user.mobile && <small className="p-error">Mobile is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>
            </Dialog>

            <Dialog visible={resetPassDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Password Reset" modal className="p-fluid" footer={PasswordDialogFooter} onHide={hideDialog}>
               <div className="field">
                    <label htmlFor="password" className="font-bold">
                        Password
                    </label>
                    <Password id="password" value={password}  onChange={(e) => setPassword(e.target.value)} required autoFocus toggleMask />
                </div>
            </Dialog>
      
            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {user && (
                        <span>
                            Are you sure you want to delete <b>{user.firstname}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
      
            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {users && <span>Are you sure you want to delete the selected records?</span>}
                </div>
            </Dialog>
        </div>
      );
    
}

export default UsersLayout