import { API_INFO } from "./APIinfo";
import { SERVICE_URL } from "./ServiceUrl";

export const getURL = (
  URL,
  PAGE_TITLE,
  PARAMS = [],
  IS_ALUMNI_SITE = false
) => {
  let formedUrl = API_INFO.BASE_URL.concat(API_INFO.PRFIX)
    .concat(API_INFO.VERSION)
    .concat(SERVICE_URL[PAGE_TITLE][URL]);

  if (IS_ALUMNI_SITE)
    formedUrl = API_INFO.ALUMNI_BASE_URL.concat(API_INFO.PRFIX)
      .concat(API_INFO.VERSION)
      .concat(SERVICE_URL[PAGE_TITLE][URL]);

  PARAMS.map((item) => {
    formedUrl = formedUrl.concat("/").concat(item);
  });

  console.log(formedUrl);
  return formedUrl;
};
