import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'

import { Outlet } from 'react-router-dom'

export const InstitutionsPage = () => {
    return (<>
        <PageHeaderComponent title="Institutions" />
        <div>
            <Outlet />
        </div>
    </>)
}
