import React from 'react'
import { Link, useParams } from 'react-router-dom';

export const SideNabBarComponent = () => {

    const { id } = useParams()
    //console.log(id);

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div className="sidebar-brand-text mx-3">HM Portal</div>
            </a>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item active">
                <a className="nav-link" href="index.html">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span></a>
            </li>
            <hr className="sidebar-divider" />

            <div className="sidebar-heading">
                CMS
            </div>

            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseHeaderMenu"
                    aria-expanded="true" aria-controls="collapseHeaderMenu">
                    <i className="fas fa-cogs"></i>
                    <span>Header Menu</span>
                </a>
                <div id="collapseHeaderMenu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Header Menu:</h6>
                        <Link className="collapse-item" to={`/wrapperPage/trustees`}>Trustees</Link>
                        <Link className="collapse-item" to={`/wrapperPage/institutions`}>Institutions</Link>
                        <Link className="collapse-item" to={`/wrapperPage/admission-links`}>Admission Links</Link>
                        <Link className="collapse-item" to={`/wrapperPage/awards`}>Awards</Link>
                        <Link className="collapse-item" to={`/wrapperPage/gallery`}>Gallery</Link>
                        <Link className="collapse-item" to={`/wrapperPage/contact`}>Contact Us</Link>
                    </div>
                </div>
            </li>


            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseFooterMenu"
                    aria-expanded="true" aria-controls="collapseFooterMenu">
                    <i className="fas fa-cogs"></i>
                    <span>Footer Menu</span>
                </a>
                <div id="collapseFooterMenu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Footer Menu:</h6>
                        <Link className="collapse-item" to={`/wrapperPage/student-portal-links`}>Student Portal Links</Link>
                        <Link className="collapse-item" to={`/wrapperPage/rti`}>Right to Information</Link>
                        <Link className="collapse-item" to={`/wrapperPage/media`}>Media Talks</Link>
                        <Link className="collapse-item" to={`/wrapperPage/links`}>Important Links</Link>
                        <Link className="collapse-item" to={`/wrapperPage/stats`}>Statistic Management</Link>
                        <Link className="collapse-item" to={`/wrapperPage/yashogatha`}>Yasho Gatha</Link>
                    </div>
                </div>

            </li>
            <hr className="sidebar-divider" />

            <div className="sidebar-heading">
                Alumini
            </div>

            <li className="nav-item">
                <Link className="nav-link" to={`/wrapperPage/alumni`}>
                    <i className="fas fa-clipboard-list"></i>
                    <span>Requests</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to={'/wrapperPage/alumni-notifications'}>
                    <i className="fas fa-bullhorn"></i>
                    <span>Notifications</span></Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={'/wrapperPage/alumni-slider'}>
                    <i className="fas fa-cogs"></i>
                    <span>Home Slider</span></Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={'/wrapperPage/alumni-gallery'}>
                    <i className="fas fa-images"></i>
                    <span>Gallery</span></Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={'/wrapperPage/alumni-events'}>
                    <i className="fas fa-file-alt"></i>
                    <span>Events</span></Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={'/wrapperPage/alumni-contact'}>
                    <i className="fas fa-envelope"></i>
                    <span>Contact</span></Link>
            </li>


            <hr className="sidebar-divider" />

            <li className="nav-item">
                <Link className="nav-link" to={`/wrapperPage/users`}>
                    <i className="fas fa-user-plus"></i>
                    <span>Users</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to={`/wrapperPage/grievances`}>
                    <i className="fas fa-heart-broken"></i>
                    <span>Grievances</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={`/wrapperPage/notices`}>
                    <i className="fas fa-bell"></i>
                    <span>Notices</span>
                </Link>
            </li>



        </ul>
    )
}
