import logo from './logo.svg';
import './App.css';

import { RoutesComponent } from './routes/routes';



function App() {
  return (
    <RoutesComponent />
  );
}

export default App;
