import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import AwardsLayout from '../../layouts/awards/awards.layout'

export const AwardsPage = () => {
    return (
        <>
            <PageHeaderComponent title="Awards" />
            <div>
                <AwardsLayout/>
            </div>
        </>
    )
}
