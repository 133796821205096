import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { deleteRecord, getData, postBody, putBody } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'
import Editor from '../../components/editor/Editor';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';


const RTILayout = () => {
    let emptyRTI = {
        id: '',
        title: '',
        description: '',
        tid: '',
        is_active: null,
        created_at: null
    }

    const [rtis, setRTIs] = useState([]);
    const [rtiDialog, setRTIDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [content, setContent] = useState(null);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [rti, setRTI] = useState(emptyRTI);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET_ALL_RTI', 'RTI'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setRTIs(resp.data.data);
            }
        }, (err) => {

        })
    }


    if (!rtis) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setRTI(emptyRTI);
        setSubmitted(false);
        setRTIDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRTIDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };


    const save = (toast) => {


        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (rti.title.trim()) {
            let _rtis = [...rtis];
            let _rti = { ...rti };

            if (rti.id) {
                const formData = new FormData();
                formData.append('id', _rti.id);
                formData.append('title', _rti.title);
                formData.append('desc', content);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('tid', '1');

                let params = [];
                params.push(rti.id);
                putBody(getURL('CREATE_RTI', 'RTI', params), formData, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RTI Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'RTI Not Update', life: 3000 });
                })


            } else {

                const formData = new FormData();
                formData.append('title', _rti.title);
                formData.append('desc', content);
                formData.append('created_by', 1);
                formData.append('is_active', '1');
                formData.append('tid', '1');

                postBody(getURL('CREATE_RTI', 'RTI'), formData, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RTI Created', life: 3000 });

                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'RTI Not Created', life: 3000 });

                })

            }

            setRTIs(_rtis);
            setRTIDialog(false);
            setRTI(emptyRTI);
        }
    };

    const edit = (rti) => {
        //console.log(rti)
        setContent(rti.description)
        setRTI({ ...rti });
        setRTIDialog(true);

    };

    const confirmDelete = (rti) => {
        setRTI(rti);
        setDeleteDialog(true);
    };


    const deleteRTI = () => {
        let _rtis = rtis.filter((val) => val.id !== rti.id);
        let params = [];
        params.push(rti.id);
        deleteRecord(getURL('CREATE_RTI', 'RTI', params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setRTIs(_rtis);
        setDeleteDialog(false);
        setRTI(emptyRTI);

    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < rtis.length; i++) {
            if (rtis[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _rtis = rtis.filter((val) => !selectedRecords.includes(val));
        setRTIs(_rtis);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, title) => {
        const val = (e.target && e.target.value) || '';
        let _rti = { ...rti };

        _rti[`${title}`] = val;

        setRTI(_rti);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const stringToHtmlBodyTemplate = (rowData) => {
        return parse(rowData.description);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage RTI</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteRTI} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={rtis} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} rtis" globalFilter={globalFilter} header={header}>
                    <Column field="id" header="ID" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="title" header="Title" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="description" header="Description" body={stringToHtmlBodyTemplate} sortable style={{ minWidth: '16rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={rtiDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="RTI Details" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>

                <div className="field">
                    <label htmlFor="title" className="font-bold">
                        Title
                    </label>
                    <InputText id="title" value={rti.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !rti.title })} />
                    {submitted && !rti.title && <small className="p-error">Title is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="desc" className="font-bold">
                        Information
                    </label>
                    <Editor id="desc" data={rti.description} setdata={setContent} required autoFocus className={classNames({ 'p-invalid': submitted && !rti.description })} />

                    {submitted && !rti.description && <small className="p-error">Information is required.</small>}
                </div>

            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {rti && (
                        <span>
                            Are you sure you want to delete <b>{rti.title}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {selectedRecords && <span>Are you sure you want to delete the selected RTIs?</span>}
                </div>
            </Dialog>
        </div>
    );



}

export default RTILayout