import React from 'react'
import { AlumniGalleryLayout } from '../../layouts/alumni/alumni-gallery.layout'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import { useParams } from 'react-router-dom'
import { AlumniGalleryPhotoLayout } from '../../layouts/alumni/alumni-gallery-photo.layout'

export const AlumniGalleryPhotoPage = () => {
    const { id } = useParams();
    // console.log(id);
    return (
        <>
            <PageHeaderComponent title="Alumni Gallery Photo" />
            <div>
                <AlumniGalleryPhotoLayout api='ALUMNI_GALLERY_PHOTO' title="Alumni Gallery" institute_id={0} gallery_id={id} />
            </div>
        </>
    )
}
