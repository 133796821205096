import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import { TrusteesLayout } from '../../layouts/trustees/trustees.layout'

export const TrusteesPage = () => {
    return (<>
        <PageHeaderComponent title="Trustees" />
        <div><TrusteesLayout /></div>
    </>
    )
}
