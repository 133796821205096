import React from 'react'

import { SideNabBarComponent } from './components/side-navbar/side-navbar.component';
import { Helmet } from "react-helmet";
import { TopProfileBarComponent } from './components/top-profilebar/top-profilebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { Outlet } from 'react-router-dom';

export const WrapperPage = () => {

    //console.log('WrapperPage');

    return (
        <div>
            <div id='page-top'>
                <div id="wrapper">
                    <SideNabBarComponent />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <TopProfileBarComponent />
                            <div className="container-fluid">
                                <div className='contain'>
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                        <FooterComponent />
                    </div>
                </div>
            </div ></div>
    )
}
