import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import LinksLayout from '../../layouts/links/links.layout'

const LinksPage = () => {
  return (
    <>
        <PageHeaderComponent title="Important Links"/>
        <div>
            <LinksLayout/>
        </div>
    </>
  )
}

export default LinksPage