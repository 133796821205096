import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Editor from '../../components/editor/Editor';
import { sourcePortalName } from '../../common/constant';
import parse from 'html-react-parser';
import { FileUpload } from 'primereact/fileupload'



export const AlumniEventLayout = ({ api, title, institute_id }) => {


    let emptyModel = {
        slider_id: null,
        image_path: '',
        title: '',
        body: '',
        created_at: '',
        created_by: '',
        institution_id: '',
        source_portal_name: '',
        start_dt: '',
        endt_dt: '',
        view_count: '',
        registration_link: ''
    }

    const [events, setEvents] = useState([]);
    const [eventDialog, setEventDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [event, setEvent] = useState(emptyModel);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [content, setContent] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET', api, [sourcePortalName()], true), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setEvents(resp.data.data);
            }
        }, (err) => {
            console.log(err)
        })
    }

    if (!events) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setEvent(emptyModel);
        setSubmitted(false);
        setEventDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setEventDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    const updateStatus = (status) => {

        if (event.id) {
            let _events = [...events];
            let _event = { ...event };

            let params = [];
            params.push(event.id);
            params.push(status);

            putData(getURL('CREATE', api, params, true), null, (resp) => {
                if (resp.status === 200 && resp.statusText === 'OK') {
                    fetchAll();
                    toast1.current.show({ severity: 'success', summary: 'Successful', detail: 'Event Updated', life: 3000 });
                }

            }, (err) => {
                toast1.current.show({ severity: 'error', summary: 'Error', detail: 'Event Not Update', life: 3000 });
            })
            setEvents(_events);
            //setEventDialog(false);
            setEvent(emptyModel);
        }
    }

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (event.title.trim()) {
            let _events = [...events];
            let _event = { ...event };


            if (event.id) {
                const data = new FormData();
                data.append('id', _event.id)
                data.append('firstname', _event.firstname);
                data.append('lastname', _event.lastname);
                data.append('email', _event.email);
                data.append('mobile', _event.mobile);
                data.append('passout_year', _event.passout_year);
                data.append('address', _event.address);
                data.append('dob', _event.dob);
                data.append('designation', _event.designation);
                data.append('organization', _event.organization);
                data.append('tid', '0');
                data.append('institute_id', institute_id);
                data.append('is_active', 'Y');
                data.append('created_by', '1');

                let params = [];
                params.push(event.id);
                putData(getURL('CREATE', api, params), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Event Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Event Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();
                data.append('title', event.title);
                data.append('body', content);
                data.append('registration_link', event.registration_link);
                data.append('start_dt', event.start_dt);
                data.append('end_dt', event.endt_dt);
                data.append('institution_id', institute_id);
                data.append('is_active', 'Y');
                data.append('created_by', 1);
                data.append('source_portal_name', sourcePortalName());
                data.append('attachment_path', selectedFile);


                postData(getURL('CREATE', api, [], true), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Event Created', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Event Not Created', life: 3000 });

                })

            }

            setEvents(_events);
            setEventDialog(false);
            setEvent(emptyModel);
        }
    };

    const edit = (event, status) => {
        setEvent({ ...event });
        updateStatus(status)
    };

    const confirmDelete = (event) => {
        setEvent(event);
        setDeleteDialog(true);
    };


    const deleteInstitute = () => {
        let _events = events.filter((val) => val.event_id !== event.event_id);
        let params = [];
        params.push(event.event_id);
        deleteRecord(getURL('DELETE', api, params, true), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setEvents(_events);
        setDeleteDialog(false);
        setEvent(emptyModel);
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };


    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _events = events.filter((val) => !selectedRecords.includes(val));
        setEvents(_events);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _event = { ...event };

        _event[`${name}`] = val;
        setEvent(_event);
    };


    const bodyTemplate = (rowData) => {
        if (rowData.body)
            return parse(rowData.body);
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>

            </div>
        );
    };



    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        if (rowData.attachment_path && (rowData.attachment_type == 'jpeg' || rowData.attachment_type == 'png' || rowData.attachment_type == 'jpg'))
            return <img src={rowData.attachment_path} alt={rowData.title} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const viewSectionTemplate = (rowData) => {
        return <Link to={`/institutions/${rowData.id}`}> <i className="fas fa-directions"></i> View/Edit Sections</Link>
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.status === "PENDING" && <Button icon="pi pi-check" rounded outlined className="mr-2" onClick={() => edit(rowData, "APPROVED")} />}
                {rowData.status === "PENDING" && <Button icon="pi pi-times" rounded outlined className="mr-2" onClick={() => edit(rowData, "DECLINED")} />}
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Alumni Events</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteInstitute} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={events} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" globalFilter={globalFilter} header={header}>

                    <Column field="title" header="Title" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="body" header="Information" body={bodyTemplate} style={{ minWidth: '10rem' }}></Column>
                    <Column field="start_dt" header="Start Date" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="end_dt" header="End Date" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="view_count" header="View Count" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="registration_link" header="Registration Link" style={{ minWidth: '10rem' }}></Column>
                    <Column field="attachment_path" header="Image" body={imageBodyTemplate}></Column>

                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>


            <Dialog visible={eventDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Create Alumni Event" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {event.image_path && <img src={event.image_path} alt={event.title} className="award-image block m-auto pb-3" />}
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Title
                    </label>
                    <InputText id="name" value={event.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !event.title })} />
                    {submitted && !event.title && <small className="p-error">Event title is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="info" className="font-bold">
                        Information
                    </label>
                    <Editor id="info" data={event.body} setdata={setContent} required autoFocus className={classNames({ 'p-invalid': submitted && !event.body })} />

                    {submitted && !event.body && <small className="p-error">Information is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="info" className="font-bold">
                        Start Date in <b>DD/MM/YYYY HH:MM</b> format
                    </label>
                    <InputText id="name" value={event.start_dt} onChange={(e) => onInputChange(e, 'start_dt')} required autoFocus className={classNames({ 'p-invalid': submitted && !event.start_dt })} />
                    {submitted && !event.start_dt && <small className="p-error">Event Start Date is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="info" className="font-bold">
                        End Date in <b>DD/MM/YYYY HH:MM</b> format
                    </label>
                    <InputText id="name" value={event.endt_dt} onChange={(e) => onInputChange(e, 'endt_dt')} required autoFocus className={classNames({ 'p-invalid': submitted && !event.endt_dt })} />
                    {submitted && !event.endt_dt && <small className="p-error">Event End Date is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="info" className="font-bold">
                        Registration Link
                    </label>
                    <InputText id="name" value={event.registration_link} onChange={(e) => onInputChange(e, 'registration_link')} required autoFocus className={classNames({ 'p-invalid': submitted && !event.registration_link })} />
                    {submitted && !event.registration_link && <small className="p-error">Registration Link is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>


            </Dialog>



            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {event && (
                        <span>
                            Are you sure you want to delete <b>{event.title}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {events && <span>Are you sure you want to delete the selected records?</span>}
                </div>
            </Dialog>
        </div>
    );
}
