import React, { useState, useEffect, useRef } from 'react';
import { getData, postBody } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

const GrievanceLayout = () => {
    const [grievance, setGrievance] = useState([]);
    const [grievances, setGrievances] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET_GRIEVANCE', 'GRIEVANCES'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setGrievances(resp.data.data);
            }
        }, (err) => {

        })
    }

    if (!grievances) {
        return <div>Loading data...</div>;
    }

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const confirmDelete = (grievance) => {
        setGrievance(grievance);
        setDeleteDialog(true);
    };


    const deleteGrievance = () => {
        let _grievances = grievances.filter((val) => val.id !== grievance.id);

        setGrievances(_grievances);
        setDeleteDialog(false);
        setGrievance(null);
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Grievances</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteGrievance} />
        </React.Fragment>
    );
    

    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={null} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={grievances} 
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} rtis" globalFilter={globalFilter} header={header}>
                    <Column field="name" header="Name" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="nmane" header="Middle Name" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="dob" header="DOB" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="enrollment" header="Enrollment" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="mobile" header="Mobile" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="email" header="Email" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="type" header="Type" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="message" header="Complaint" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {grievance && (
                        <span>
                            Are you sure you want to delete <b>{grievance.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            
        </div>
    );
}

export default GrievanceLayout