import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import GrievanceLayout from '../../layouts/grievance/grievance.layout'

const GrievancePage = () => {
    return(
        <>
            <PageHeaderComponent title="Grievances"/>
            <div>
                <GrievanceLayout/>
            </div>
        </>
    )
}

export default GrievancePage