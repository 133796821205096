import React, { useState } from 'react'
import './institute-section.layout.scss'
import { useParams } from 'react-router-dom';
import AchievementLayout from '../../sections/achievement.layout';
import SectionCKEditorLayout from '../../sections/section-ckeditor.layout';
import HmLayout from '../../sections/hm.layout';
import InfraFacilityLayout from '../../sections/infra-facility.layout';
import GalleryLayout from '../../sections/gallery.layout';
import SchoolCalendarLayout from '../../sections/school-calendar.layout';

export const InstituteSectionLayout = () => {

    const { id } = useParams();


    const menuLayout = [
        { id: 0, 'menu_title': 'School Information', component: <SectionCKEditorLayout api='SCHOOL_INFO' title='School Information' institute_id={id} /> },
        { id: 1, 'menu_title': 'Past Results', component: <SectionCKEditorLayout api='PAST_RESULT' title='Past Results' institute_id={id} is_file_allowed={true} /> },
        { id: 2, 'menu_title': 'HM/Principal Information', component: <HmLayout title='HM/Principal Info' institute_id={id}/> },
        { id: 3, 'menu_title': 'PTA Members', component: <SectionCKEditorLayout api='SCHOOL_PTA_MEMBER' title='PTA Member' institute_id={id}  is_file_allowed={true}/> },
        { id: 4, 'menu_title': 'Awards', component: <AchievementLayout api='ACHIEVEMENT_TEACHER' title='Awards' institute_id={id} isTeacher={true} /> },
        { id: 5, 'menu_title': 'Contact Information', component: <SectionCKEditorLayout api='SCHOOL_CONTACT' title='Contact Information' institute_id={id} /> },
        { id: 6, 'menu_title': 'Academic Calendar', component: <SchoolCalendarLayout api='SCHOOL_CALENDAR' title='School Calendar' institute_id={id}/> },
        { id: 7, 'menu_title': 'Scholarships', component: <SectionCKEditorLayout api='SCHOLARSHIP' title='Scholarships' institute_id={id} /> },
        { id: 8, 'menu_title': 'Activities Offered', component: <SectionCKEditorLayout api='SCHOOL_ACTIVITY' title='Activities Offered' institute_id={id}/> },
        { id: 9, 'menu_title': 'Infrastructure Offered', component: <InfraFacilityLayout api='INFRASTRUCTURE' title='Infrastructure' is_infra={true} institute_id={id} /> },
        { id: 10, 'menu_title': 'Other Activities', component: <SectionCKEditorLayout api='SCHOOL_OTHER_ACTIVITY' title='Other Activities' institute_id={id}/> },
        { id: 11, 'menu_title': 'Facilities Offered', component: <InfraFacilityLayout api='FACILITY' title='Facility' is_infra={false} institute_id={id} /> },
        { id: 12, 'menu_title': 'Student Achievements', component: <AchievementLayout api='ACHIEVEMENT_STUDENT' title='Student Achievements' institute_id={id} isTeacher= {false} /> },
        { id: 13, 'menu_title': 'Gallery', component: <GalleryLayout api='GALLERY' title='Gallery' is_infra={null} institute_id={id} /> },
        { id: 14, 'menu_title': 'Admission Information', component: <SectionCKEditorLayout api='ADMISSION_INFO' title='Admission Information' institute_id={id} /> },
    ]

    const [selectedMenuDetails, setSelectedMenuDetails] = useState(menuLayout[0]);
    const [selectedMenu, setSelectedMenu] = useState(0);

    //get Institute Details
    return (
        <div className='ins-sec-layout'>
            <div className='col-sm-12'>
                <div className='row'>
                    <div className='col-sm-2 left-menu'>
                        {
                            menuLayout.map(item => {
                                return <div onClick={() => {
                                    setSelectedMenu(item.id);
                                    setSelectedMenuDetails(item);
                                }} className={item.id == selectedMenu ? 'card active' : 'card'} key={item.id}>
                                    <div >
                                        {item.menu_title}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className='col-sm-10'>
                        {selectedMenuDetails.component}
                    </div>
                </div>
            </div>
        </div>
    )
}
