import React from 'react'
import { AlumniGalleryLayout } from '../../layouts/alumni/alumni-gallery.layout'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'

export const AlumniGalleryPage = () => {
    return (
        <>
            <PageHeaderComponent title="Alumni Gallery" />
            <div>
                <AlumniGalleryLayout api='ALUMNI_GALLERY' title="Alumni Gallery" institute_id={0} />
            </div>
        </>
    )
}
