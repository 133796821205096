export const SERVICE_URL = {
  TRUSTEE: {
    GET_ALL_TRUSTEES: "/trustee",
    CREATE_TRUSTEE: "/trustee",
  },
  AWARDS: {
    GET_ALL_AWARDS: "/awards",
    CREATE_AWARDS: "/awards",
  },

  RTI: {
    GET_ALL_RTI: "/rti",
    CREATE_RTI: "/rti",
  },

  IMPORTANT_LINKS: {
    GET_ALL_IMPORTANT_LINS: "/links",
    CREATE_LINKS: "/links",
  },

  NOTICE: {
    GET_ALL_NOTICES: "/notice",
    GET: "/notice",
    CREATE: "/notice",
  },

  NOTICE_TYPE: {
    GET_ALL_NOTICE_TYPES: "/notice/type",
  },

  MEDIA_TALKS: {
    GET_ALL_MEDIA_TALKS: "/media",
    CREATE_MEDIA_TALKS: "/media",
  },

  STUDENT_ACHIEVEMENTS: {
    GET_ALL_ACHIEVEMENT: "/achievement",
    GET_ACHIEVEMENT_TYPES: "/achievement/type",
  },

  GRIEVANCES: {
    GET_GRIEVANCE: "/complaint",
  },

  MAGZINE: {
    GET_MAGZINE: "/magzine",
    CREATE_MAGZINE: "/magzine",
  },

  ACADEMIC_YEARS: {
    GET_ACADEMIC_YEAR: "/academic/year",
  },

  INSTITUTE: {
    GET_INSTITUTE: "/institute",
    CREATE_INSTITUTE: "/institute",
  },
  ALUMNI: {
    GET: "/alumni/register/",
    CREATE: "/alumni/register/",
  },
  USERS: {
    GET: "/user/profile",
    CREATE: "/user/profile",
    USER_CREATE: "/users",
    CHANGE_PASSWORD: "/users/reset/password",
  },

  ADMISSION_STUDENT_PORTAL_LINKS: {
    GET: "/portal-links",
    CREATE: "/portal-links",
  },

  //Section details
  SCHOOL_INFO: {
    GET: "/school/info",
    CREATE: "/school/info",
  },
  SCHOOL_STRENGTH: {
    GET: "/school/strength",
    CREATE: "/school/strength",
  },
  PAST_RESULT: {
    GET: "/past/result",
    CREATE: "/past/result",
  },
  SCHOOL_HM: {
    GET: "/principal",
    CREATE: "/principal",
  },
  SCHOOL_CALENDAR: {
    GET: "/school/calendar",
    CREATE: "/school/calendar",
  },
  INFRASTRUCTURE: {
    GET: "/school/infra",
    CREATE: "/school/infra",
  },
  FACILITY: {
    GET: "/school/facility",
    CREATE: "/school/facility",
  },
  GALLERY: {
    GET: "/school/gallery",
    CREATE: "/school/gallery",
  },
  SCHOLARSHIP: {
    GET: "/scholarship",
    CREATE: "/scholarship",
  },
  ADMISSION_INFO: {
    GET: "/admission/info",
    CREATE: "/admission/info",
  },
  SCHOOL_CONTACT: {
    GET: "/school/contact",
    CREATE: "/school/contact",
  },
  SCHOOL_ACTIVITY: {
    GET: "/school/activity",
    CREATE: "/school/activity",
  },
  SCHOOL_OTHER_ACTIVITY: {
    GET: "/other/activity",
    CREATE: "/other/activity",
  },
  SCHOOL_PTA_MEMBER: {
    GET: "/school/pta/member",
    CREATE: "/school/pta/member",
  },
  ACHIEVEMENT_TYPE: {
    GET_ACHIEVEMENT_TYPE: "/achievement/type",
    CREATE_ACHIEVEMENT_TYPE: "/achievement/type",
  },
  ACHIEVEMENT_STUDENT: {
    GET: "/achievement/student",
    CREATE: "/achievement",
  },
  ACHIEVEMENT_TEACHER: {
    GET: "/achievement/teacher",
    CREATE: "/achievement",
  },
  /*SCHOOL_INFO: {
    GET_SCHOOL_INFO: "/school/info",
    CREATE_SCHOOL_INFO: "/school/info",
  },
  SCHOOL_STRENGTH: {
    GET_SCHOOL_STRENGTH: "/school/strength",
    CREATE_SCHOOL_STRENGTH: "/school/strength",
  },
  PAST_RESULT: {
    GET_PAST_RESULT: "/past/result",
    CREATE_PAST_RESULT: "/past/result",
  },
  SCHOLARSHIP: {
    GET_SCHOLARSHIP: "/scholarship",
    CREATE_SCHOLARSHIP: "/scholarship",
  },
  ADMISSION_INFO: {
    GET_ADMISSION_INFO: "/admission/info",
    CREATE_ADMISSION_INFO: "/admission/info",
  },
  SCHOOL_CONTACT: {
    GET_SCHOOL_CONTACT: "/school/contact",
    CREATE_SCHOOL_CONTACT: "/school/contact",
  },
  SCHOOL_ACTIVITY: {
    GET_SCHOOL_ACTIVITY: "/school/activity",
    CREATE_SCHOOL_ACTIVITY: "/school/activity",
  },
  SCHOOL_PTA_MEMBER: {
    GET_SCHOOL_PTA_MEMBER: "/school/pta/member",
    CREATE_SCHOOL_PTA_MEMBER: "/school/pta/member",
  },
  ACHIEVEMENT_TYPE: {
    GET_ACHIEVEMENT_TYPE: "/achievement/type",
    CREATE_ACHIEVEMENT_TYPE: "/achievement/type",
  },
  ACHIEVEMENT: {
    GET_TEACHER_ACHIEVEMENT: "/achievement/teacher",
    GET_STUDENT_ACHIEVEMENT: "/achievement/student",
    CREATE_ACHIEVEMENT: "/achievement",
  },*/

  APP_COMMON: {
    LOGIN: "/users/login",
    MY_PROFILE: "/user/profile",
  },
  ALUMNI_HOME_SLIDER: {
    CREATE: "/slider",
    GET: "/slider",
    DELETE: "/slider",
  },
  ALUMNI_NOTIFICATION: {
    CREATE: "/notification",
    GET: "/notification",
    DELETE: "/notification",
  },

  ALUMNI_EVENT: {
    CREATE: "/event",
    GET: "/event",
    DELETE: "/event",
  },
  ALUMNI_GALLERY: {
    CREATE: "/gallery",
    GET: "/gallery",
    DELETE: "/gallery",
  },
  ALUMNI_GALLERY_PHOTO: {
    CREATE: "/gallery/photo",
    GET: "/gallery/photo",
    DELETE: "/gallery/photo",
  },
  ALUMNI_CONTACT: {
    CREATE: "/contact",
    GET: "/contact"
  }
};
