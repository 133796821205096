import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import Editor from '../../components/editor/Editor';
import { sourcePortalName } from '../../common/constant';
import parse from 'html-react-parser';


export const AlumniGalleryPhotoLayout = ({ api, title, institute_id, gallery_id }) => {


    let emptyModel = {
        photo_id: null,
        gallery_id: gallery_id,
        image_path: '',
        caption: '',
        created_at: '',
        created_by: '',
        institution_id: '',
        source_portal_name: ''
    }

    const [galls, setGalls] = useState([]);
    const [gallDialog, setGallDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [gall, setGall] = useState(emptyModel);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [content, setContent] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET', api, [sourcePortalName(), gallery_id], true), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setGalls(resp.data.data);
            }
        }, (err) => {
            console.log(err)
        })
    }

    if (!galls) {
        return <div>Loading data...</div>;
    }




    const openNew = () => {
        setGall(emptyModel);
        setSubmitted(false);
        setGallDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setGallDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };

    const updateStatus = (status) => {

        if (gall.id) {
            let _requests = [...galls];
            let _request = { ...gall };

            let params = [];
            params.push(gall.gallery_id);
            params.push(status);

            putData(getURL('CREATE', api, params, true), null, (resp) => {
                if (resp.status === 200 && resp.statusText === 'OK') {
                    fetchAll();
                    toast1.current.show({ severity: 'success', summary: 'Successful', detail: 'Gallery Photo Updated', life: 3000 });
                }

            }, (err) => {
                toast1.current.show({ severity: 'error', summary: 'Error', detail: 'Gallery Photo Not Update', life: 3000 });
            })
            setGalls(_requests);
            //setGallDialog(false);
            setGall(emptyModel);
        }
    }

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (gall.caption.trim()) {
            let _requests = [...galls];
            let _request = { ...gall };


            if (gall.photo_id) {
                const data = new FormData();
                data.append('id', _request.id)
                data.append('firstname', _request.firstname);
                data.append('lastname', _request.lastname);
                data.append('email', _request.email);
                data.append('mobile', _request.mobile);
                data.append('passout_year', _request.passout_year);
                data.append('address', _request.address);
                data.append('dob', _request.dob);
                data.append('designation', _request.designation);
                data.append('organization', _request.organization);
                data.append('tid', '0');
                data.append('institute_id', institute_id);
                data.append('is_active', 'Y');
                data.append('created_by', '1');

                let params = [];
                params.push(gall.gallery_id);
                putData(getURL('CREATE', api, params), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Gallery Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Gallery Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();

                data.append('caption', gall.caption);
                data.append('institution_id', institute_id);
                data.append('is_active', 'Y');
                data.append('created_by', 1);
                data.append('source_portal_name', sourcePortalName());
                data.append('gallery_id', gallery_id);
                data.append('image_path', selectedFile);


                postData(getURL('CREATE', api, [], true), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Gallery Photo Created', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Gallery Photo Not Created', life: 3000 });

                })

            }

            setGalls(_requests);
            setGallDialog(false);
            setGall(emptyModel);
        }
    };

    const edit = (gall, status) => {
        setGall({ ...gall });
        updateStatus(status)
    };

    const confirmDelete = (gall) => {
        setGall(gall);
        setDeleteDialog(true);
    };


    const deleteInstitute = () => {
        let _requests = galls.filter((val) => val.photo_id !== gall.photo_id);
        let params = [];
        params.push(gall.photo_id);
        deleteRecord(getURL('CREATE', api, params, true), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setGalls(_requests);
        setDeleteDialog(false);
        setGall(emptyModel);
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _requests = galls.filter((val) => !selectedRecords.includes(val));
        setGalls(_requests);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _request = { ...gall };

        _request[`${name}`] = val;
        setGall(_request);
    };


    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>

            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        if (rowData.image_path)
            return <img src={rowData.image_path} alt={rowData.title} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const viewSectionTemplate = (rowData) => {
        return <Link to={`/institutions/${rowData.id}`}> <i className="fas fa-directions"></i> View/Edit Sections</Link>
    };

    const bodyTemplate = (rowData) => {
        if (rowData.body)
            return parse(rowData.body);

    }


    const actionBodyTemplate = (rowData) => {
        return (

            <React.Fragment>
                {rowData.status === "PENDING" && <Button icon="pi pi-check" rounded outlined className="mr-2" onClick={() => edit(rowData, "APPROVED")} />}
                {rowData.status === "PENDING" && <Button icon="pi pi-times" rounded outlined className="mr-2" onClick={() => edit(rowData, "DECLINED")} />}
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Alumni Gallery</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteInstitute} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );


    return (

        <div className="card shadow">
            <Outlet />
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={galls} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" globalFilter={globalFilter} header={header}>

                    <Column field="caption" header="Title" sortable style={{ minWidth: '10rem' }}></Column>

                    <Column header="Image" body={imageBodyTemplate}></Column>

                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>


            <Dialog visible={gallDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Create Alumni Gallery" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>

                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Caption
                    </label>
                    <InputText id="name" value={gall.caption} onChange={(e) => onInputChange(e, 'caption')} required autoFocus className={classNames({ 'p-invalid': submitted && !gall.caption })} />
                    {submitted && !gall.caption && <small className="p-error">Caption is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>

            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {gall && (
                        <span>
                            Are you sure you want to delete <b>{gall.caption}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {galls && <span>Are you sure you want to delete the selected records?</span>}
                </div>
            </Dialog>
        </div>
    );
}

