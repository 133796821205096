import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import UsersLayout from '../../layouts/users/users.layout'


const UsersPage = () => {
  return (
    <>
        <PageHeaderComponent title="Users" />
        <UsersLayout api='USERS' title="Users" />
    </>
  )
}

export default UsersPage