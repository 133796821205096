import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { useParams } from 'react-router-dom';
import "./institute.layout.scss"

const InstituteLayout = () => {

    const { id } = useParams()

    let emptyModel = {
        name: '',
        address: '',
        logo: null,
        tid: 1,
        created_by: 1,
        is_active: null
    }

    const [institutes, setInstitutes] = useState([]);
    const [institueDialog, setInstituteDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [institute, setInstitute] = useState(emptyModel);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET_INSTITUTE', 'INSTITUTE'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setInstitutes(resp.data.data);
            }
        }, (err) => {
            //console.log(err)
        })
    }

    if (!institutes) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setInstitute(emptyModel);
        setSubmitted(false);
        setInstituteDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setInstituteDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };

    const save = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (institute.name.trim()) {
            let _institutes = [...institutes];
            let _institute = { ...institute };


            if (institute.id) {
                const data = new FormData();
                data.append('id', _institute.id)
                data.append('name', _institute.name);
                data.append('address', _institute.address);
                data.append('tid', '1');
                data.append('created_by', '1');
                data.append('is_active', '1');
                data.append('logo', selectedFile);
                data.append('updated_by', '1');

                let params = [];
                params.push(institute.id);
                putData(getURL('CREATE_INSTITUTE', 'INSTITUTE', params), data, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Institute Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Institute Not Update', life: 3000 });
                })


            } else {

                const data = new FormData();
                data.append('name', _institute.name);
                data.append('address', _institute.address);
                data.append('tid', '1');
                data.append('created_by', '1');
                data.append('is_active', '1');
                data.append('logo', selectedFile);


                postData(getURL('CREATE_INSTITUTE', 'INSTITUTE'), data, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Institute Created', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Institute Not Created', life: 3000 });

                })

            }

            setInstitutes(_institutes);
            setInstituteDialog(false);
            setInstitute(emptyModel);
        }
    };

    const edit = (institute) => {
        setInstitute({ ...institute });
        setInstituteDialog(true);
    };

    const confirmDelete = (institute) => {
        setInstitute(institute);
        setDeleteDialog(true);
    };


    const deleteInstitute = () => {
        let _institutes = institutes.filter((val) => val.id !== institute.id);
        let params = [];
        params.push(institute.id);
        deleteRecord(getURL('CREATE_INSTITUTE', 'INSTITUTE', params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setInstitutes(_institutes);
        setDeleteDialog(false);
        setInstitute(emptyModel);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < institutes.length; i++) {
            if (institutes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _institutes = institutes.filter((val) => !selectedRecords.includes(val));
        setInstitutes(_institutes);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _institute = { ...institute };

        _institute[`${name}`] = val;
        setInstitute(_institute);
    };


    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>

            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        if (rowData.url)
            return <img src={rowData.url} alt={rowData.name} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const viewSectionTemplate = (rowData) => {
        return <Link to={`/wrapperPage/institutions/${rowData.id}`}> <i className="fas fa-directions"></i> View/Edit Sections</Link>
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Awards</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => save(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteInstitute} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };

    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={institutes} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} institutes" globalFilter={globalFilter} header={header}>

                    <Column field="id" header="ID" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="name" header="Name" sortable style={{ minWidth: '14rem' }}></Column>
                    <Column field="address" header="Address" sortable style={{ minWidth: '14rem' }}></Column>
                    <Column field="url" header="Image" body={imageBodyTemplate}></Column>
                    <Column header="Sections" body={viewSectionTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={institueDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Institute Details" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {institute.url && <img src={institute.url} alt={institute.name} className="institute-image block m-auto pb-3" />}
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={institute.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !institute.name })} />
                    {submitted && !institute.name && <small className="p-error">Name is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="address" className="font-bold">
                        Address
                    </label>
                    <InputText id="address" value={institute.address} onChange={(e) => onInputChange(e, 'address')} required autoFocus className={classNames({ 'p-invalid': submitted && !institute.address })} />
                    {submitted && !institute.address && <small className="p-error">Address is required.</small>}
                </div>


                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>


            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {institute && (
                        <span>
                            Are you sure you want to delete <b>{institute.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {institutes && <span>Are you sure you want to delete the selected Media talks?</span>}
                </div>
            </Dialog>
        </div>
    );


}

export default InstituteLayout