import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import { AlumniHomeSliderLayout } from '../../layouts/alumni/alumni-home-slider.layout'

export const AlumniHomeSliderPage = () => {
    return (
        <>
            <PageHeaderComponent title="Alumni Home Slider" />
            <div>
                <AlumniHomeSliderLayout api='ALUMNI_HOME_SLIDER' title="Alumni home Slider" institute_id={0} />
            </div>
        </>
    )
}
