import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import NoticeLayout from '../../layouts/notices/notice.layout'

const NoticesPage = () => {
  return (
    <>
        <PageHeaderComponent title="Notices" />
        <NoticeLayout api='NOTICE' title="Notices" institute_id={0} />
    </>
  )
}

export default NoticesPage