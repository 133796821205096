import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { deleteRecord, getData, postData, putData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';

import './awards.layout.scss';
import Editor from '../../components/editor/Editor';

const AwardsLayout = () => {
  let emptyAwards = {
    id:'',
    awardee_name:'',
    content:'',
    avtar_url:'',
    is_active:null,
    created_at: null
  }

  const [awards, setAwards] = useState([]);
    const [awardsDialog, setAwardsDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [content, setContent] = useState('');
    const [deleteMultipleDialog, setDeleteMultipleDialog] = useState(false);
    const [award, setAward] = useState(emptyAwards);
    const [selectedRecords, setSelectedRecords] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const toast1 = useRef(null);
    const dt = useRef(null);
    const isPageAalreadyLoaded = useRef(false);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        if (isPageAalreadyLoaded.current) return;
        isPageAalreadyLoaded.current = true;
        setMounted(true);
        fetchAll();
    }, []);

    const fetchAll = () => {
        getData(getURL('GET_ALL_AWARDS', 'AWARDS'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setAwards(resp.data.data);
            }
        }, (err) => {

        })
    }


    if (!awards) {
        return <div>Loading data...</div>;
    }

    const openNew = () => {
        setAward(emptyAwards);
        setSubmitted(false);
        setAwardsDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setAwardsDialog(false);
    };

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const hideDeleteMultipleDialog = () => {
        setDeleteMultipleDialog(false);
    };



    const saveAward = (toast) => {
        // this.showToast('success', 'Successful', 'Trustee Updated');
        setSubmitted(true);

        if (award.awardee_name.trim()) {
            let _awards = [...awards];
            let _award = { ...award };

            if (award.id) {
                const formData = new FormData();
                formData.append('id', _award.id);
                formData.append('avtar', selectedFile);
                formData.append('awardee_name', _award.awardee_name);
                formData.append('content', content);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('tid', '1');

                let params = [];
                params.push(award.id);
                putData(getURL('CREATE_AWARDS', 'AWARDS',params), formData, (resp) => {
                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Award Updated', life: 3000 });
                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Award Not Update', life: 3000 });
                })


            } else {

                const formData = new FormData();
                formData.append('avtar', selectedFile);
                formData.append('awardee_name', _award.awardee_name);
                formData.append('content', content);
                formData.append('created_by', '1');
                formData.append('is_active', '1');
                formData.append('tid', '1');


                postData(getURL('CREATE_AWARDS', 'AWARDS'), formData, (resp) => {

                    if (resp.status === 200 && resp.statusText === 'OK') {
                        fetchAll();
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Award Created', life: 3000 });

                    }

                }, (err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Award Not Created', life: 3000 });

                })

            }

            setAwards(_awards);
            setAwardsDialog(false);
            setAward(emptyAwards);
        }
    };

    const edit = (award) => {
        setAward({ ...award });
        setContent(award.content)
        setAwardsDialog(true);

    };

    const confirmDelete = (award) => {
        setAward(award);
        setDeleteDialog(true);
    };


    const deleteAward = () => {
        let _awards = awards.filter((val) => val.id !== award.id);
        let params = [];
            params.push(award.id);
        deleteRecord(getURL('CREATE_AWARDS', 'AWARDS', params), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                fetchAll();
             //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
            }
        }, (err) => {
           // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data Not Update', life: 3000 });
        })
        setAwards(_awards);
        setDeleteDialog(false);
        setAward(emptyAwards);

    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < awards.length; i++) {
            if (awards[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteMultipleDialog(true);
    };

    const deleteSelectedRecords = () => {
        let _awards = awards.filter((val) => !selectedRecords.includes(val));
        
        setAwards(_awards);
        setDeleteMultipleDialog(false);
        setSelectedRecords(null);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _award = { ...award };

        _award[`${name}`] = val;

        setAward(_award);
    };

    
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <a className="btn btn-success btn-icon-split" severity="success" onClick={openNew}>
                    <span className="icon text-white-50">
                        <i className="fas fa-check"></i>
                    </span>
                    <span className="text">Create</span>
                </a>
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <a className="btn btn-warning btn-icon-split" onClick={exportCSV}>
            <span className="icon text-white-50">
                <i className="fas fa-download"></i>
            </span>
            <span className="text">Export</span>
        </a>
    };

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.avtar_url} alt={rowData.image} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const stringToHtmlBodyTemplate = (rowData) =>{
        return parse(rowData.content);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => edit(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Awards</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (

        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={() => saveAward(toast1)} />
        </React.Fragment>
    );
    const deleteRecordDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteAward} />
        </React.Fragment>
    );
    const deleteRecordsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteMultipleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedRecords} />
        </React.Fragment>
    );

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };


    return (
        <div className="card shadow">
            <Toast ref={toast1} />
            <div>
                <Toolbar className="mb-4 toolbar-overidden" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={awards} selection={selectedRecords} onSelectionChange={(e) => setSelectedRecords(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} awards" globalFilter={globalFilter} header={header}>
                    
                    <Column field="id" header="ID" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="awardee_name" header="Name" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="content" header="Description" body={stringToHtmlBodyTemplate} sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="avtar" header="Image" body={imageBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={awardsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Awards Details" modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {award.avtar_url && <img src={award.avtar_url} alt={award.awardee_name} className="award-image block m-auto pb-3" />}
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={award.awardee_name} onChange={(e) => onInputChange(e, 'awardee_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !award.awardee_name })} />
                    {submitted && !award.awardee_name && <small className="p-error">Name is required.</small>}
                </div>
                
                <div className="field">
                    <label htmlFor="info" className="font-bold">
                        Information
                    </label>
                    <Editor id="info" data={award.content} setdata={setContent} required autoFocus className={classNames({ 'p-invalid': submitted && !award.content })}/>
                    
                    {submitted && !award.content && <small className="p-error">Information is required.</small>}
                </div>

                <div className="field">
                    <label className="mb-3 font-bold">Photo chooser</label>
                    <div className="formgrid grid">
                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                            chooseLabel="Browse" onSelect={onFileSelect} />
                    </div>
                </div>


            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {award && (
                        <span>
                            Are you sure you want to delete <b>{award.awardee_name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteMultipleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteRecordsDialogFooter} onHide={hideDeleteMultipleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {awards && <span>Are you sure you want to delete the selected Awards?</span>}
                </div>
            </Dialog>
        </div>
    );

}

export default AwardsLayout