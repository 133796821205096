import React from 'react'
import { PageHeaderComponent } from '../../components/page-header/page-header.component'
import MediaTalkLayout from '../../layouts/media/media.layout'

const MediaTalkPage = () => {
  return (
    <>
        <PageHeaderComponent title="Media Talks" />
        <div>
            <MediaTalkLayout/>
        </div>
    </>
  )
}

export default MediaTalkPage